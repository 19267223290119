import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import { get } from "lodash";
import { stringify } from "query-string";
import React from "react";
import {
	EditButton,
	Pagination,
	ReferenceManyField,
	TopToolbar,
	useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ResponsiveList from "../../components/lists/ResponsiveList";
import { useListContentStyles } from "../../components/lists/useStyles";
import DeleteWithConfirmButton from "../buttons/DeleteWithConfirmButton";

const _makeRedirect = ({ resource, record, tab, show }) => {
	return `/${resource}/${get(record, "id")}/${show ? "show/" : ""}${tab}`;
};

export const makeRedirect = (props) => _makeRedirect(props);

const PaginationWrapper = ({ fullWidth, addLabel, ...props }) => (
	<Pagination {...props} />
);

const useStyles = makeStyles((theme) => ({
	toolbar: {},
	smartButton: {
		[theme.breakpoints.down("md")]: {
			padding: 0,
			"& .MuiButton-label": {
				padding: 0,
			},
			"& .MuiButton-label > span": {
				display: "none",
				visibility: "hidden",
				padding: 0,
			},
		},
	},
}));

const ListField = ({
	removeRedirect,
	helperText = null,
	reference,
	target,
	fullWidth,
	expand,
	mobileHeader,
	mobileBody,
	mobileActions,
	canCreate,
	canModify,
	canRemove,
	useLabel = true,
	show = false,
	...props
}) => {
	const { source, resource, record } = props;
	const translate = useTranslate();
	const classes = useListContentStyles();
	const customClasses = useStyles();

	const { label } = props;
	if (!removeRedirect) {
		removeRedirect = makeRedirect({ resource, record, tab: 0, show });
	}

	return (
		<div className={classes.root}>
			{useLabel && (
				<Typography variant="subtitle1">
					{translate(label || `resources.${resource}.fields.${source}`)}
				</Typography>
			)}
			{useLabel && <Divider />}
			{helperText !== null && (
				<Typography
					component="div"
					variant="body1"
					dangerouslySetInnerHTML={{ __html: translate(helperText) }}
				/>
			)}
			<ReferenceManyField
				{...props}
				reference={reference}
				target={target}
				filter={{ [target]: get(record, "id", 0) }}
				pagination={<PaginationWrapper />}
			>
				<ResponsiveList
					classes={{ table: classes.table, rowEven: classes.rowEven }}
					expand={expand}
					mobileHeader={mobileHeader}
					mobileBody={mobileBody}
					mobileActions={mobileActions}
					empty={translate(`resources.${reference}.messages.empty`)}
				>
					{React.Children.map(props.children, (field, index) =>
						React.isValidElement(field)
							? React.cloneElement(field, { key: index })
							: null
					)}
					{canModify && <EditButton />}
					{canRemove && <DeleteWithConfirmButton redirect={removeRedirect} />}
				</ResponsiveList>
			</ReferenceManyField>
			{canCreate && (
				<TopToolbar resource={resource} className={customClasses.toolbar}>
					<Button
						startIcon={<ContentAdd />}
						component={Link}
						disableElevation
						variant="outlined"
						color="primary"
						to={{
							pathname: `/${reference}/create`,
							search: stringify({
								source: JSON.stringify({ [target]: get(record, "id") }),
							}),
						}}
					>
						{translate(
							`resources.${resource}.buttons.add_${get(props, "source")}`
						)}
					</Button>
				</TopToolbar>
			)}
		</div>
	);
};

export default ListField;
