import { get } from "lodash";
import React from "react";
import ReadButton from "./ReadButton";
import UnreadButton from "./UnreadButton";

const ReadUnreadButton = ({ source, record, ...props }) => {
	const readed = get(record, source);
	const id = get(record, "id");
	return (
		<React.Fragment>
			{readed ? (
				<UnreadButton selectedIds={[id]} />
			) : (
				<ReadButton selectedIds={[id]} />
			)}
		</React.Fragment>
	);
};

export default ReadUnreadButton;
