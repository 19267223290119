let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/") || appUrl.endsWith(":3001/")) {
	// appUrl = "//topquota.local/";
	appUrl = "https://app.topquota.it/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const SIGNUP_URL = `${APP_URL}signup/`;
export const VERSION = "1.0.0";
export const ENVIRONMENT = environment;
