import { useTheme } from "@material-ui/core";
import React from "react";
import {
	CloneButton,
	EditButton,
	List as RaList,
	TextField,
} from "react-admin";
import TextFieldInput from "../components/fields/TextFieldInput";
import HelpProvider from "../components/HelpProvider";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	const theme = useTheme();
	return (
		<React.Fragment>
			<HelpProvider />
			<RaList
				{...props}
				classes={classes}
				filters={<Filters />}
				perPage={perPage(50, 25)}
				sort={{ field: "id", order: "DESC" }}
			>
				<ResponsiveList
					mobileHeader={
						<React.Fragment>
							<TextField
								source="code"
								variant="subtitle1"
								style={{ fontWeight: "bold" }}
							/>
							<EditButton />
						</React.Fragment>
					}
					mobileBody={
						<React.Fragment>
							<TextField source="text" color="textSecondary" />
						</React.Fragment>
					}
					mobileActions={
						<React.Fragment>
							<TextField
								source="language.name"
								label="resources.language-messages.fields.language_id"
								variant="overline"
								style={{ fontWeight: "bold", padding: theme.spacing(2) }}
							/>
						</React.Fragment>
					}
				>
					<TextField source="id" label="ID" />
					<TextField
						source="language.name"
						label="resources.language-messages.fields.language_id"
					/>
					<TextField source="code" style={{ fontWeight: "bold" }} />
					<TextFieldInput source="text" color="textSecondary" />
					<EditButton />
					<CloneButton />
				</ResponsiveList>
			</RaList>
		</React.Fragment>
	);
};
export default List;
