import { Checkbox, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useNotify, useRefresh, useTranslate, useUpdate } from "react-admin";

const Input = ({
	value,
	primaryKey,
	record,
	source,
	basePath,
	resource,
	label,
	...props
}) => {
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const [edit, { loading }] = useUpdate(
		resource,
		primaryKey,
		{ [source]: !value },
		record,
		{
			onSuccess: () => {
				notify(translate("ra.notification.updated", 1));
				refresh();
			},
		}
	);

	return (
		<Typography variant="body1" component="div" {...props}>
			<Checkbox checked={value} disabled={loading} onChange={edit} />
		</Typography>
	);
};

const BooleanFieldInput = (props) => {
	const { source, record } = props;
	const primaryKey = get(record, "id");
	const value = get(record, source);
	return <Input value={value} primaryKey={primaryKey} {...props} />;
};

export default BooleanFieldInput;
