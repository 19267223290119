import React from "react";
import { Edit as RaEdit, useNotify, useRefresh } from "react-admin";
import { useFormStyles } from "../components/forms/useStyles";
import Title from "../components/Title";
import Form from "./Form";

const Edit = (props) => {
	const classes = useFormStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const onSuccess = (data) => {
		notify("ra.notification.updated", "info", { smart_count: 1 });
		refresh();
	};
	return (
		<RaEdit
			{...props}
			classes={classes}
			title={<Title source="name" />}
			mutationMode="pessimistic"
		>
			<Form created onSuccess={onSuccess} />
		</RaEdit>
	);
};
export default Edit;
