import React from "react";
import { Filter as RaFilter, SearchInput } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<RaFilter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
		</RaFilter>
	);
};
export default Filters;
