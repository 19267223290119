import React from "react";
import { Filter, SearchInput, SelectInput } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import TextInput from "../components/pages/input/TextInput";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<SelectInput
				source="is_menu"
				alwaysOn
				fullWidth
				emptyText="ra.action.all"
				allowEmpty
				choices={[
					{ id: true, name: "ra.action.yes" },
					{ id: false, name: "ra.action.no" },
				]}
			>
				<TextInput />
			</SelectInput>
		</Filter>
	);
};
export default Filters;
