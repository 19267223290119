import React from "react";
import {
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import types from "./types";

const Form = (props) => {
	return (
		<SimpleForm {...props} redirect="list">
			<ReferenceInput
				source="language_id"
				reference="languages"
				validate={required()}
			>
				<SelectInput />
			</ReferenceInput>
			<SelectInput source="type" choices={types} validate={required()} />
			<TextInput source="from" validate={required()} fullWidth />
			<TextInput source="to" validate={required()} fullWidth />
		</SimpleForm>
	);
};
export default Form;
