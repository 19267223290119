import React from "react";
import { Edit as RaEdit } from "react-admin";
import { useFormStyles } from "../components/forms/useStyles";
import Title from "../components/Title";
import Form from "./Form";

const Edit = (props) => {
	const classes = useFormStyles();
	return (
		<RaEdit
			{...props}
			classes={classes}
			title={<Title source="name" />}
			mutationMode="pessimistic"
		>
			<Form />
		</RaEdit>
	);
};
export default Edit;
