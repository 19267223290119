import * as React from "react";
import { cloneElement } from "react";
import {
	CreateButton,
	sanitizeListRestProps,
	TopToolbar,
	useListContext,
} from "react-admin";
import OrderButton from "../components/buttons/OrderButton";

const Actions = (props) => {
	const { className, exporter, filters, maxResults, ...rest } = props;
	const { resource, displayedFilters, filterValues, basePath, showFilter } =
		useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{filters &&
				cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button",
				})}
			<CreateButton basePath={basePath} />
			<OrderButton resource={resource} />
		</TopToolbar>
	);
};

export default Actions;
