import SortIcon from "@material-ui/icons/Sort";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Button, Confirm, useMutation, useRefresh } from "react-admin";

const OrderButton = ({ resource }) => {
	const [open, setOpen] = useState(false);
	const refresh = useRefresh();
	const [order, { loading }] = useMutation(
		{
			type: "post",
			resource: `${resource}/order`,
			payload: {},
		},
		{
			onSuccess: () => refresh(),
		}
	);
	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);
	const handleConfirm = () => {
		order();
		setOpen(false);
	};
	return (
		<Fragment>
			<Button label="ra.action.order.label" onClick={handleClick}>
				<SortIcon />
			</Button>
			<Confirm
				isOpen={open}
				loading={loading}
				title="ra.action.order.title"
				content="ra.action.order.content"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</Fragment>
	);
};

OrderButton.propTypes = {
	resource: PropTypes.string.isRequired,
};

export default OrderButton;
