import { InputAdornment } from "@material-ui/core";
import React from "react";
import {
	BooleanInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import useValidatableSave from "../components/forms/useValidatableSave";

const Form = ({ toolbar, create, onSuccess, ...props }) => {
	const save = useValidatableSave({ ...props, onSuccess });
	return (
		<SimpleForm {...props} save={save}>
			<TextInput source="name" validate={required()} fullWidth />
			{create && <TextInput source="code" validate={required()} />}
			<ReferenceInput source="country_id" reference="countries">
				<SelectInput />
			</ReferenceInput>
			<BooleanInput source="is_menu" />
			<BooleanInput source="is_home" />
			<BooleanInput source="is_championship" />
			<BooleanInput source="is_cup" />
			<TextInput
				source="seo_slug"
				fullWidth
				InputProps={{
					startAdornment: <InputAdornment position="start">/</InputAdornment>,
				}}
			/>
		</SimpleForm>
	);
};
export default Form;
