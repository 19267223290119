// in src/posts.js
import * as React from "react";
import { Show as RaShow, SimpleShowLayout } from "react-admin";
import { useFormStyles } from "../components/forms/useStyles";
import Title from "../components/Title";
import ShowField from "./fields/ShowField";

const Show = (props) => {
	const classes = useFormStyles();
	return (
		<RaShow
			title={<Title source="name" />}
			actions={null}
			{...props}
			classes={classes}
		>
			<SimpleShowLayout>
				<ShowField />
			</SimpleShowLayout>
		</RaShow>
	);
};

export default Show;
