import { get } from "lodash";
import React from "react";
import {
	ChipField,
	DateField,
	List as RaList,
	NumberField,
	TextField,
	useTranslate,
} from "react-admin";
import Pagination from "../components/lists/Pagination";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const Datagrid = (props) => {
	const hasBookmakerFilter = get(props, "filterValues.bookmaker_id");
	const translate = useTranslate();
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="match.fullname" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<ChipField
						source="bookmaker.name"
						label="resources.match-bets.fields.bookmaker_id"
					/>
					<TextField
						source="bet_category.name"
						label="resources.match-bets.fields.bet_category_id"
					/>
					<TextField
						source="bet.name"
						label="resources.match-bets.fields.bet_id"
					/>
					<NumberField source="quote" options={{ minimumFractionDigits: 2 }} />
					<DateField source="created" showTime />
					<DateField source="modified" showTime />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			<TextField
				source="match.fullname"
				style={{ fontWeight: "bold" }}
				label={translate("resources.matches.name", 1)}
			/>
			{!hasBookmakerFilter && (
				<ChipField
					source="bookmaker.name"
					label="resources.match-bets.fields.bookmaker_id"
				/>
			)}
			<TextField
				source="bet_category.name"
				label="resources.match-bets.fields.bet_category_id"
				style={{ fontWeight: "bold" }}
				variant="overline"
			/>
			<TextField
				source="bet.name"
				label="resources.match-bets.fields.bet_id"
				variant="overline"
			/>
			<NumberField source="quote" options={{ minimumFractionDigits: 2 }} />
			<DateField source="created" showTime />
			<DateField source="modified" showTime />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage()}
			sort={{ field: "date", order: "DESC" }}
			pagination={<Pagination />}
			exporter={false}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
