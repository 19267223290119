export default [
	{
		id: "approved",
		name: "resources.user-profile-approvals.status.approved",
	},
	{
		id: "pending",
		name: "resources.user-profile-approvals.status.pending",
	},
	{
		id: "rejected",
		name: "resources.user-profile-approvals.status.rejected",
	},
];
