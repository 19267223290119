import React from "react";
import {
	DateField,
	EditButton,
	List as RaList,
	NumberField,
	TextField,
} from "react-admin";
import OrderIndexField from "../components/fields/OrderIndexField";
import TextFieldInput from "../components/fields/TextFieldInput";
import Pagination from "../components/lists/Pagination";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const Datagrid = (props) => {
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="name" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<NumberField source="count_bookmaker_bets" />
					<DateField showTime source="modified" />
					<DateField showTime source="created" />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			<TextFieldInput source="name" style={{ fontWeight: "bold" }} />
			<OrderIndexField source="order" />
			<DateField showTime source="modified" />
			<DateField showTime source="created" />
			<EditButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage()}
			sort={{ field: "order", order: "ASC" }}
			pagination={<Pagination />}
			exporter={false}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
