import { Button } from "@material-ui/core";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useRedirect } from "react-admin";

const FilterField = ({
	children,
	reference,
	target,
	targetSource,
	record,
	filter = {},
	...props
}) => {
	const filterValue = get(record, targetSource);
	const redirect = useRedirect();
	const url = `/${reference}?order=${encodeURIComponent(
		JSON.stringify({ order: "ASC", sort: "name" })
	)}&filter=${encodeURIComponent(
		JSON.stringify({
			...filter,
			[target]: filterValue,
		})
	)}`;
	const handleClick = () => redirect("list", url);
	return (
		<Button variant="text" color="primary" onClick={handleClick}>
			{React.cloneElement(children, { ...props, record })}
		</Button>
	);
};

FilterField.propTypes = {
	reference: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	targetSource: PropTypes.string.isRequired,
};

export default FilterField;
