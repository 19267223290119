import React from "react";
import {
	AutocompleteInput,
	Filter,
	ReferenceInput,
	SearchInput,
	SelectInput,
	TextInput,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<ReferenceInput
				source="country_id"
				reference="countries"
				alwaysOn
				fullWidth
				sort={{
					field: "name",
					order: "ASC",
				}}
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<SelectInput
				source="is_menu"
				alwaysOn
				fullWidth
				emptyText="ra.action.all"
				allowEmpty
				choices={[
					{ id: true, name: "ra.action.yes" },
					{ id: false, name: "ra.action.no" },
				]}
			>
				<TextInput />
			</SelectInput>
			<SelectInput
				source="is_home"
				alwaysOn
				fullWidth
				emptyText="ra.action.all"
				allowEmpty
				style={{ minWidth: 200 }}
				choices={[
					{ id: true, name: "ra.action.yes" },
					{ id: false, name: "ra.action.no" },
				]}
			>
				<TextInput />
			</SelectInput>
		</Filter>
	);
};
export default Filters;
