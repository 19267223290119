import { useMediaQuery } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import {
  ArrayField,
  ChipField,
  DateField,
  EditButton,
  EmailField,
  List as RaList,
  NumberField,
  SingleFieldList,
  TextField,
  useTranslate,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles, useSelector } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import StatusField from "./fields/StatusField";
import Filters from "./Filters";
import LoginButton from "./LoginButton";

const Datagrid = ({ ...props }) => {
  const xl = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const hasChildrenFilter = get(props, "filterValues.has_children");
  const translate = useTranslate();
  return (
    <ResponsiveList
      {...props}
      mobileHeader={
        <React.Fragment>
          <TextField
            source="name"
            label="resources.users.fields.profile.name"
            style={{ fontWeight: "bold" }}
            variant="subtitle1"
          />
          <EditButton />
        </React.Fragment>
      }
      mobileBody={
        <React.Fragment>
          <DateField source="created" showTime color="textSecondary" />
          <DateField
            source="last_login"
            showTime
            color="textSecondary"
            emptyText={translate("ra.page.empty")}
          />
          <StatusField source="status" />
        </React.Fragment>
      }
      mobileActions={
        <React.Fragment>
          <LoginButton />
        </React.Fragment>
      }
    >
      <TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
      <TextField
        source="profile.name"
        sortBy="UserProfiles.name"
        style={{ fontWeight: "bold" }}
      />
      <TextField
        source="profile.surname"
        sortBy="UserProfiles.surname"
        style={{ fontWeight: "bold" }}
      />
      {lg && <EmailField source="email" />}
      {hasChildrenFilter && (
        <NumberField source="count_children" sortable={false} />
      )}
      <DateField source="created" showTime />
      <DateField source="last_login" showTime />
      <StatusField source="status" />
      {xl && (
        <ArrayField source="groups" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" style={{ marginLeft: 0 }} />
          </SingleFieldList>
        </ArrayField>
      )}
      <ArrayField source="roles" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" style={{ marginLeft: 0 }} />
        </SingleFieldList>
      </ArrayField>
      <LoginButton />
      <EditButton />
    </ResponsiveList>
  );
};

const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  return (
    <RaList
      {...props}
      classes={classes}
      perPage={perPage(50, 25)}
      filters={<Filters />}
      sort={{ field: "created", order: "DESC" }}
    >
      <Datagrid {...props} />
    </RaList>
  );
};

export default List;
