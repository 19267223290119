import { TeamsIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "topquota",
		roles: ["admin"],
	},
	icon: TeamsIcon,
	list: List,
	create: Create,
	edit: Edit,
};
