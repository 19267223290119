import React from "react";
import { Layout } from "react-admin";
import AppBar from "./customAppBar";

export default (props) => {
	return (
		<React.Fragment>
			<Layout {...props} appBar={AppBar} />
		</React.Fragment>
	);
};
