import { InputAdornment } from "@material-ui/core";
import React from "react";
import {
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import types from "./types";

const Form = (props) => {
	return (
		<SimpleForm {...props} redirect="list">
			<ReferenceInput
				source="bookmaker_id"
				reference="bookmakers"
				sort={{
					field: "name",
					order: "ASC",
				}}
				validate={required()}
			>
				<SelectInput />
			</ReferenceInput>
			<NumberInput source="from" validate={required()} />
			<NumberInput source="to" validate={required()} />
			<NumberInput
				source="value"
				validate={required()}
				InputProps={{
					endAdornment: <InputAdornment position="end">%</InputAdornment>,
				}}
			/>
			<SelectInput source="type" choices={types} validate={required()}>
				<TextInput />
			</SelectInput>
		</SimpleForm>
	);
};
export default Form;
