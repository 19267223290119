import { withErrors } from "ra-remote-validator";
import React from "react";
import { Create as RaCreate } from "react-admin";
import { compose } from "recompose";
import { useFormStyles } from "../components/forms/useStyles";
import Form from "./Form";

const Create = ({ dispatch, validate, errors, permissions, ...props }) => {
	const classes = useFormStyles();
	return (
		<RaCreate {...props} classes={classes} redirect="list">
			<Form
				permissions={permissions}
				dispatch={dispatch}
				validate={validate}
				errors={errors}
				create={true}
			/>
		</RaCreate>
	);
};

export default compose(withErrors)(Create);
