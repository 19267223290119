import React from "react";
import {
	Filter,
	ReferenceInput,
	SearchInput,
	SelectInput,
	TextInput,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<ReferenceInput
				source="macro_bet_category_id"
				reference="macro-bet-categories"
				sort={{ order: "ASC", field: "order" }}
				fullWidth
				alwaysOn
				style={{ minWidth: 220 }}
				emptyText="ra.action.all"
			>
				<SelectInput />
			</ReferenceInput>
			<SelectInput
				source="is_summary"
				alwaysOn
				fullWidth
				emptyText="ra.action.all"
				allowEmpty
				style={{ minWidth: 200 }}
				choices={[
					{ id: true, name: "ra.action.yes" },
					{ id: false, name: "ra.action.no" },
				]}
			>
				<TextInput />
			</SelectInput>
		</Filter>
	);
};
export default Filters;
