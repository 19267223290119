import { Box, makeStyles, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import ReadUnreadButton from "../ReadUnreadButton";
import ViewField from "./ViewField";

const useStyles = makeStyles((theme) => ({
	title: {},
	content: {},
	root: {
		maxWidth: 700,
	},
	actions: {
		display: "flex",
	},
}));

const ReadedField = ({ record, source, ...props }) => {
	const classes = useStyles();
	const title = get(record, "title", "");
	const content = get(record, "content", "");
	return (
		<div className={classes.root}>
			<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
				{title}
			</Typography>
			<Typography
				variant="body1"
				className={classes.content}
				color="textSecondary"
				gutterBottom
				style={{ whiteSpace: "normal" }}
			>
				{content}
			</Typography>
			<Box className={classes.actions}>
				<ViewField
					{...props}
					source="resource"
					label="resources.notifications.actions.view"
					record={record}
				/>
				<ReadUnreadButton source="readed" record={record} {...props} />
			</Box>
		</div>
	);
};
export default ReadedField;
