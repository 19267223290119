export default [
  {
    id: "pending",
    name: "resources.users.status.pending",
  },
  {
    id: "active",
    name: "resources.users.status.active",
  },
  {
    id: "banned",
    name: "resources.users.status.banned",
  },
  {
    id: "suspended",
    name: "resources.users.status.suspended",
  },
];
