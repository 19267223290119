import { useTheme } from "@material-ui/core";
import React from "react";
import { Filter, ReferenceInput, SelectInput } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import TextInput from "../components/pages/input/TextInput";
import types from "./types";

const Filters = (props) => {
	const classes = useFiltersStyles();
	const theme = useTheme();
	return (
		<Filter {...props} classes={classes}>
			<ReferenceInput
				source="bookmaker_id"
				reference="bookmakers"
				sort={{
					field: "name",
					order: "ASC",
				}}
				allowEmpty
				emptyText="ra.action.all"
				alwaysOn
				fullWidth
				style={{ marginTop: theme.spacing(4) }}
			>
				<SelectInput />
			</ReferenceInput>
			<SelectInput source="type" choices={types} alwaysOn fullWidth>
				<TextInput />
			</SelectInput>
		</Filter>
	);
};
export default Filters;
