import { Grid } from "@material-ui/core";
import { get } from "lodash";
import React, { Fragment } from "react";
import { BooleanInput, required, TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import GridSingle from "../../components/GridSingle";

const UserInput = ({ ...props }) => {
	return (
		<React.Fragment>
			<GridSingle md={5}>
				<TextInput
					source="old_password"
					type="password"
					validate={[required()]}
					fullWidth
					{...props}
				/>
			</GridSingle>
			<GridSingle md={5}>
				<TextInput
					source="password"
					label="resources.users.fields.new_password"
					type="password"
					fullWidth
					validate={[required()]}
					{...props}
				/>
			</GridSingle>
			<GridSingle md={5}>
				<TextInput
					source="new_password_confirm"
					type="password"
					fullWidth
					validate={[required()]}
					{...props}
				/>
			</GridSingle>
		</React.Fragment>
	);
};

const PasswordInput = ({ create = false, permissions, ...props }) => {
	const formState = useFormState();
	const changePassword = get(formState.values, `change_password`, false);
	const form = useForm();
	return (
		<Fragment>
			<BooleanInput
				source="change_password"
				defaultValue={create}
				onChange={(canChange) => {
					if (!canChange) {
						form.batch(() => {
							form.change("password", undefined);
							form.change("old_password", undefined);
							form.change("new_password", undefined);
							form.change("new_password_confirm", undefined);
						});
					}
				}}
				{...props}
			/>
			{changePassword && (
				<Grid container>
					{permissions && permissions(["admin", "developer"]) ? (
						<GridSingle md={5}>
							<TextInput
								source="password"
								type="password"
								fullWidth
								{...props}
							/>
						</GridSingle>
					) : (
						<UserInput {...props} />
					)}
				</Grid>
			)}
		</Fragment>
	);
};

export default PasswordInput;
