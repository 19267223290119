import { get } from "lodash";
import React from "react";
import { Button, useRedirect, useTranslate } from "react-admin";
import { BetsIcon } from "../icons";

const MatchBetsButton = ({ record }) => {
	const redirect = useRedirect();
	const translate = useTranslate();
	return (
		<Button
			label={translate("resources.matches.actions.match_bets", {
				count: get(record, "count_match_bets"),
			})}
			onClick={() =>
				redirect(
					`/match-bets?filter=${encodeURIComponent(
						JSON.stringify({
							match_id: get(record, "id"),
						})
					)}`
				)
			}
		>
			<BetsIcon />
		</Button>
	);
};

export default MatchBetsButton;
