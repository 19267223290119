import { makeStyles, TextField, Typography } from "@material-ui/core";
import { get } from "lodash";
import React, { useState } from "react";
import { useNotify, useRefresh, useTranslate, useUpdate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	cover: {
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
}));

const Field = ({ value, setEdit, source, record, basePath, ...props }) => {
	return (
		<Typography onClick={() => setEdit(true)} variant="body2" {...props}>
			{value}
		</Typography>
	);
};

const Input = ({
	value,
	setEdit,
	primaryKey,
	record,
	source,
	basePath,
	resource,
	label,
	...props
}) => {
	const classes = useStyles();
	const [updatedValue, setUpdatedValue] = useState(value);
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const [edit, { loading }] = useUpdate(
		resource,
		primaryKey,
		{ [source]: updatedValue },
		record,
		{
			onSuccess: () => {
				setEdit(false);
				notify(translate("ra.notification.updated", 1));
				refresh();
			},
		}
	);
	return (
		<Typography variant="body1" component="div" {...props}>
			<div className={classes.cover} onClick={edit} />
			<TextField
				label={label || translate(`resources.${resource}.fields.${source}`)}
				variant="filled"
				autoFocus
				margin="dense"
				defaultValue={updatedValue}
				disabled={loading}
				fullWidth
				onChange={(e) => setUpdatedValue(e.target.value)}
			/>
		</Typography>
	);
};

const TextFieldInput = (props) => {
	const { source, record } = props;
	const primaryKey = get(record, "id");
	const value = get(record, source);
	const [edit, setEdit] = useState(false);
	if (edit) {
		return (
			<Input
				value={value}
				primaryKey={primaryKey}
				setEdit={setEdit}
				{...props}
			/>
		);
	}
	return <Field value={value} setEdit={setEdit} {...props} />;
};

export default TextFieldInput;
