import React from "react";
import { DateField, EditButton, List as RaList, TextField } from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import NameOrLogoField from "./fields/NameOrLogoField";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			perPage={perPage(50, 25)}
			filters={<Filters />}
			sort={{ field: "name", order: "ASC" }}
			exporter={false}
		>
			<ResponsiveList
				mobileBody={
					<React.Fragment>
						<NameOrLogoField source="name" style={{ fontWeight: "bold" }} />
						<DateField source="modified" showTime />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<EditButton />
					</React.Fragment>
				}
			>
				<TextField
					source="id"
					label="ID"
					style={{ fontWeight: "bold" }}
					variant="h5"
				/>
				<NameOrLogoField source="name" style={{ fontWeight: "bold" }} />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};

export default List;
