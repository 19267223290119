import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
	DateTimeInput,
	FormTab,
	required,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import ImageInput from "../components/inputs/ImageInput";

const Form = (props) => {
	const translate = useTranslate();
	return (
		<TabbedForm {...props} redirect="list">
			<FormTab label={translate("resources.publications.name", 1)}>
				<TextInput source="title" validate={required()} fullWidth />
				<TextInput source="code" validate={required()} fullWidth />
				<RichTextInput source="content" multline fullWidth {...props} />
				<DateTimeInput source="date" />
				<ImageInput
					source="image"
					label="resources.publications.fields.image_media_id"
				/>
			</FormTab>
			<FormTab label="resources.publications.og">
				<TextInput source="og_title" fullWidth />
				<TextInput source="og_description" multiline rows={5} fullWidth />
				<ImageInput
					source="og_image"
					label="resources.publications.fields.og_image_media_id"
				/>
			</FormTab>
		</TabbedForm>
	);
};
export default Form;
