import {
	Badge,
	createStyles,
	List,
	makeStyles,
	withStyles,
} from "@material-ui/core";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { get } from "lodash";
import { usePermissions } from "ra-core";
import React, { createElement, useState } from "react";
import {
	getResources,
	MenuItemLink,
	Responsive,
	translate,
	useQueryWithStore,
} from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SubMenu from "./SubMenu";

const GROUPS_ORDER = {
	dashboard: 5,
	topquota: 30,
	pages: 50,
	bookmakers: 70,
	content: 90,
	admin: 100,
};
const GROUPS_VISIBILITY = {
	dashboard: true,
	topquota: true,
	pages: false,
	bookmakers: true,
	content: true,
	admin: true,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		marginRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		boxShadow: theme.shadows[24],
		[theme.breakpoints.up("sm")]: {
			height: "100%",
		},
		[theme.breakpoints.down("md")]: {
			paddingBottom: "6em",
		},
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const mapGroups = (resources, permissions) => {
	let groups = []
		.concat(resources.filter((r) => r.hasList && r.options && r.icon))
		.filter(
			(item) =>
				permissions &&
				(item.options.roles === undefined ||
					item.options.roles.filter((role) => permissions(role)).length > 0)
		)
		.reduce((groups, resource) => {
			let groupName = resource.options ? resource.options.group : "";
			let group = groups.find((g) => g.name === groupName);
			if (group) {
				group.resources.push(resource);
				group.resources.sort((a, b) =>
					a.options.order > b.options.order
						? 1
						: a.options.order < b.options.order
						? -1
						: 0
				);
			} else {
				group = {
					name: groupName,
					order: GROUPS_ORDER[groupName] || 1000,
					resources: [resource],
				};
				groups.push(group);
			}
			return groups;
		}, []);

	groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
	return groups;
};

const isGroupSelected = (location, group) =>
	group.resources &&
	group.resources.some(
		(resource) =>
			location.pathname === `/${resource.name}` ||
			location.pathname === resource.path ||
			location.pathname.indexOf(`/${resource.name}?`) === 0 ||
			location.pathname.indexOf(`/${resource.name}/`) === 0
	);
const isResourceSelected = (location, resource) =>
	location.pathname === `/${resource.name}` ||
	location.pathname === resource.path ||
	location.pathname.indexOf(`/${resource.name}?`) === 0 ||
	(location.pathname.indexOf(`/${resource.name}/`) === 0 &&
		!location.pathname.endsWith("/create"));

const styles = (theme) =>
	createStyles({
		version: {
			padding: theme.spacing(1),
		},
	});
const SafeBadge = ({ titleAccess, children, ...props }) => (
	<Badge {...props}>{children}</Badge>
);

const Menu = ({
	resources,
	onMenuClick,
	logout,
	open,
	translate,
	location,
	hasDashboard,
}) => {
	const { permissions } = usePermissions();
	const [visibility, setVisibility] = useState({ ...GROUPS_VISIBILITY });
	const customClasses = useStyles();

	const { data: badges } = useQueryWithStore({
		type: "getBadges",
	});

	return (
		<List component="nav" className={customClasses.root}>
			{mapGroups(resources, permissions, hasDashboard).map((group) => (
				<SubMenu
					key={group.name}
					handleToggle={() =>
						setVisibility({
							...visibility,
							[group.name]: visibility[group.name] !== true,
						})
					}
					isOpen={visibility[group.name] || isGroupSelected(location, group)}
					sidebarIsOpen={open}
					name={`menu.groups.${group.name}`}
					icon={<SubMenuIcon />}
				>
					{group.resources.map((resource) => {
						let to = `${resource.path || `/${resource.name}`}`;
						return (
							<MenuItemLink
								key={resource.path || resource.name}
								to={to}
								isActive={(match, location) =>
									isResourceSelected(location, resource)
								}
								primaryText={
									resource.options && resource.options.title
										? resource.options.title
										: resource.options && resource.options.label
										? translate(resource.options.label)
										: translate(`menu.items.${resource.name}`)
								}
								leftIcon={
									get(badges, `data.${resource.name}.show`) ? (
										<SafeBadge
											color={get(badges, `data.${resource.name}.type`)}
											variant={get(badges, `data.${resource.name}.variant`)}
											badgeContent={get(badges, `data.${resource.name}.value`)}
										>
											{createElement(resource.icon)}
										</SafeBadge>
									) : (
										createElement(resource.icon)
									)
								}
								onClick={onMenuClick}
								button
							/>
						);
					})}
				</SubMenu>
			))}
			<Responsive
				small={logout}
				medium={null} // Pass null to render nothing on larger devices
			/>
		</List>
	);
};

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
	resources: getResources(state),
});

export default translate(
	withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)))
);
