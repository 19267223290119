import { useTheme } from "@material-ui/core";
import React from "react";
import {
	BooleanField,
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	TextField,
} from "react-admin";
import OrderIndexField from "../components/fields/OrderIndexField";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	const theme = useTheme();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "order", order: "asc" }}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField
							source="name"
							variant="subtitle1"
							style={{ fontWeight: "bold" }}
						/>
						<EditButton />
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<TextField source="code" variant="overline" />
						<BooleanField source="is_menu" />
						<DateField source="modified" showTime color="textSecondary" />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<TextField
							source="language.name"
							label="resources.pages.fields.language_id"
							variant="overline"
							style={{ fontWeight: "bold", padding: theme.spacing(2) }}
						/>
					</React.Fragment>
				}
			>
				<OrderIndexField source="order" />
				<TextField source="id" label="ID" />
				<TextField
					source="language.name"
					label="resources.pages.fields.language_id"
				/>
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ChipField source="code" style={{ marginLeft: 0 }} />

				<BooleanField source="is_menu" />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
