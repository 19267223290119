import { Chip } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import PanToolIcon from "@material-ui/icons/PanTool";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";

const STATUS_COLOR_MAP = {
	pending: orange,
	approved: green,
	rejected: red,
};

const STATUS_ICON_MAP = {
	pending: PanToolIcon,
	approved: CheckIcon,
	rejected: BlockIcon,
};
const StatusField = ({ source, record }) => {
	const translate = useTranslate();
	const status = get(record, source);
	const Icon = get(STATUS_ICON_MAP, status, BlockIcon);
	const color = get(STATUS_COLOR_MAP, `${status}.500`, "");
	return (
		<Chip
			icon={<Icon style={{ color: "white" }} />}
			style={{
				backgroundColor: color,
				color: "white",
			}}
			label={translate(`resources.user-profile-approvals.status.${status}`)}
		/>
	);
};

export default StatusField;
