import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import "moment/locale/it";
import { errorsReducer, errorsSaga } from "ra-remote-validator";
import * as React from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import authProvider from "./authProvider";
import betCategories from "./bet-categories";
import bets from "./bets";
import bookmakerBonuses from "./bookmaker-bonuses";
import bookmakers from "./bookmakers";
import helpReducer from "./components/helpReducer";
import Menu from "./components/Menu";
import countries from "./countries";
import customLayout from "./customLayout";
import Dashboard from "./dashboard";
import dataProvider from "./dataProvider";
import domainItems from "./domain-items";
import domains from "./domains";
import events from "./events";
import i18nProvider from "./i18nProvider";
import languageMessages from "./language-messages";
import macroBetCategories from "./macro-bet-categories";
import matchBets from "./match-bets";
import matches from "./matches";
import notifications from "./notifications";
import pages from "./pages";
import profile from "./profile";
import publications from "./publications";
import redirects from "./redirects";
import sliders from "./sliders";
import teams from "./teams";
import theme from "./theme";
import useI18nCatcher from "./useI18nCatcher";
import useLanguages from "./useLanguages";
import userProfileApprovals from "./user-profile-approvals";
import userReferrals from "./user-referrals";
import users from "./users";
import LoginPage from "./users/pages/LoginPage";
import PasswordChangePage from "./users/pages/PasswordChangePage";
import PasswordResetPage from "./users/pages/PasswordResetPage";
import SignupPage from "./users/pages/SignupPage";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const App = () => {
	useI18nCatcher();
	const { loading, languages } = useLanguages();
	const classes = useStyles();
	if (loading) {
		return (
			<Backdrop className={classes.backdrop} open={true}>
				<CircularProgress color="primary" />
			</Backdrop>
		);
	}

	return (
		<Admin
			title="topquota.it"
			menu={Menu}
			theme={theme}
			dashboard={Dashboard}
			dataProvider={dataProvider}
			authProvider={authProvider}
			i18nProvider={i18nProvider(languages)}
			loginPage={LoginPage}
			layout={customLayout}
			customReducers={{
				errors: errorsReducer,
				help: helpReducer,
			}}
			customSagas={[errorsSaga]}
			customRoutes={[
				<Route
					path="/password-reset"
					component={PasswordResetPage}
					noLayout
					options={{ menu: false }}
				/>,
				<Route path="/password-change" component={PasswordChangePage} />,
				<Route
					path="/signup"
					component={SignupPage}
					noLayout
					options={{ menu: false }}
				/>,
				<Route key="profile" path="/profile" component={profile.edit} />,
			]}
		>
			<Resource name="notifications" {...notifications} />
			<Resource name="matches" {...matches} />
			<Resource name="match-bets" {...matchBets} />
			<Resource name="domains" {...domains} />
			<Resource name="domain-items" {...domainItems} />
			<Resource name="events" {...events} />
			<Resource name="teams" {...teams} />
			<Resource name="macro-bet-categories" {...macroBetCategories} />
			<Resource name="bet-categories" {...betCategories} />
			<Resource name="bets" {...bets} />
			<Resource name="sub-bets" />
			<Resource name="countries" {...countries} />
			<Resource name="bookmakers" {...bookmakers} />
			<Resource name="bookmaker-bonuses" {...bookmakerBonuses} />
			<Resource name="users" {...users} />
			<Resource name="user-profile-approvals" {...userProfileApprovals} />
			<Resource name="user-referrals" {...userReferrals} />
			<Resource name="publications" {...publications} />
			<Resource name="sliders" {...sliders} />
			<Resource name="pages" {...pages} />
			<Resource name="redirects" {...redirects} />
			<Resource name="language-messages" {...languageMessages} />
			<Resource name="roles" />
			<Resource name="resources" />
			<Resource name="groups" />
			<Resource name="languages" />
			<Resource name="profile" />
		</Admin>
	);
};
export default App;
