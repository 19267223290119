import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";

const Form = ({ toolbar, create, ...props }) => {
	return (
		<SimpleForm {...props} redirect="list">
			<TextInput source="name" validate={required()} fullWidth />
			{create && <TextInput source="code" validate={required()} />}
		</SimpleForm>
	);
};
export default Form;
