import { useTheme } from "@material-ui/core";
import React from "react";
import {
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	TextField,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	const theme = useTheme();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "modified", order: "DESC" }}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField
							source="fullname"
							variant="subtitle1"
							style={{ fontWeight: "bold" }}
						/>
						<EditButton />
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<TextField source="type" variant="overline" />
						<DateField source="modified" showTime color="textSecondary" />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<TextField
							source="language.name"
							label="resources.redirects.fields.language_id"
							variant="overline"
							style={{ fontWeight: "bold", padding: theme.spacing(2) }}
						/>
					</React.Fragment>
				}
			>
				<TextField source="id" label="ID" />
				<TextField
					source="language.name"
					label="resources.redirects.fields.language_id"
				/>
				<ChipField source="type" />
				<TextField source="fullname" style={{ fontWeight: "bold" }} />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
