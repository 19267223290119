import React from "react";
import {
	NumberInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import ImageInput from "../components/inputs/ImageInput";
import operators from "./operators";

const Form = ({ toolbar, create, ...props }) => {
	return (
		<SimpleForm {...props}>
			<TextInput source="name" validate={required()} fullWidth />
			{create && <TextInput source="code" validate={required()} />}
			<ImageInput
				source="logo"
				label="resources.bookmakers.fields.logo_media_id"
			/>
			<ImageInput
				source="logo_transparent"
				label="resources.bookmakers.fields.logo_transparent_media_id"
			/>
			<TextInput source="url" fullWidth validate={required()} />
			<TextInput source="signup_bonus" />
			<NumberInput source="bonus_quote_threshold" />
			<SelectInput
				source="bonus_quote_threshold_operator"
				choices={operators}
				defaultValue=">="
			/>
		</SimpleForm>
	);
};
export default Form;
