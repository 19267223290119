import { InputAdornment } from "@material-ui/core";
import { get } from "lodash";
import React, { useMemo } from "react";
import {
	AutocompleteInput,
	FormTab,
	maxLength,
	ReferenceInput,
	required,
	TabbedForm,
	TextInput,
	usePermissions,
} from "react-admin";
import useValidatableSave from "../components/forms/useValidatableSave";
import ImageInput from "../components/inputs/ImageInput";
import useNetwork from "../useNetwork";
import AlertField from "./fields/AlertField";
import ItemsField from "./fields/ItemsField";
import ThemeSelectInput from "./inputs/ThemeSelectInput";

const Form = ({ created, onSuccess, ...props }) => {
	const save = useValidatableSave({ ...props, onSuccess });
	const { loaded, permissions } = usePermissions();
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	const { network } = useNetwork();
	return (
		<TabbedForm {...props} save={save}>
			<FormTab label="resources.domains.tabs.general">
				{isAdmin && (
					<ReferenceInput
						source="user_id"
						reference="users"
						validate={required()}
					>
						<AutocompleteInput optionText="name" />
					</ReferenceInput>
				)}
				<TextInput
					source="domain_name"
					validate={required()}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								.{get(network, "domain_name", "")}
							</InputAdornment>
						),
					}}
				/>
				<ImageInput
					source="logo"
					label="resources.domains.fields.logo_media_id"
				/>
				<ImageInput
					source="favicon"
					label="resources.domains.fields.favicon_media_id"
				/>
				<ThemeSelectInput source="theme" validate={required()} />
				<TextInput source="facebook_pixel" validate={maxLength(100)} />
				<TextInput source="google_analytics" validate={maxLength(100)} />
			</FormTab>
			<FormTab label="resources.domains.tabs.og">
				<TextInput source="og_title" validate={maxLength(250)} fullWidth />
				<TextInput source="og_description" fullWidth multiline />
				<ImageInput
					source="og_image"
					label="resources.domains.fields.og_image_media_id"
				/>
			</FormTab>
			{created && (
				<FormTab label="resources.domains.tabs.items">
					<AlertField source="is_valid" />
					<ItemsField source="items" fullWidth />
				</FormTab>
			)}
		</TabbedForm>
	);
};
export default Form;
