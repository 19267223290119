import React from "react";
import {
	DateField,
	EditButton,
	ImageField,
	List as RaList,
	TextField,
} from "react-admin";
import OrderIndexField from "../components/fields/OrderIndexField";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "order", order: "ASC" }}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField source="title" style={{ fontWeight: "bold" }} />
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<ImageField
							source="image.file.path"
							label="resources.sliders.fields.image_media_id"
						/>
						<TextField source="title" style={{ fontWeight: "bold" }} />
						<DateField source="modified" showTime />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<EditButton />
					</React.Fragment>
				}
			>
				<TextField source="id" label="ID" />
				<ImageField
					source="image.file.path"
					label="resources.sliders.fields.image_media_id"
				/>
				<TextField source="title" style={{ fontWeight: "bold" }} />
				<OrderIndexField source="order" />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
