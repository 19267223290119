import React from "react";
import { CloneButton as RaCloneButton } from "react-admin";
const CloneButton = ({ record, ...props }) => {
	if (!record) {
		return null;
	}
	const { id, bookmaker, created, modified, value, deleted, ...rest } = record;
	const newRecord = {
		...rest,
		name: `${rest.name} copy`,
		order: rest.order + 1,
	};
	return <RaCloneButton record={newRecord} {...props} />;
};
export default CloneButton;
