import { MatchsIcon } from "../icons";
import List from "./List";

export default {
	options: {
		group: "dashboard",
		roles: ["admin"],
	},
	icon: MatchsIcon,
	list: List,
};
