import React from "react";
import { SelectInput } from "react-admin";

const themes = [
	{
		id: "default",
		name: "default",
	},
];

const ThemeSelectInput = (props) => (
	<SelectInput
		choices={themes}
		optionText="name"
		defaultValue="default"
		{...props}
	/>
);
export default ThemeSelectInput;
