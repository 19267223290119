import { get } from "lodash";
import React from "react";
import {
	AutocompleteInput,
	DateInput,
	DateTimeInput,
	Filter,
	ReferenceInput,
	SelectInput,
} from "react-admin";
import { bookmakersConfig } from "../bookmakers";
import DateRangeInput from "../components/inputs/DateRangeInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<DateRangeInput
				source="view"
				displayedFilters={props.displayedFilters}
				filterValues={props.filterValues}
				setFilters={props.setFilters}
				alwaysOn
				fullWidth
				style={{ marginTop: 32 }}
			/>
			{get(props, "filterValues.view") === "range" && (
				<DateTimeInput source="created" alwaysOn fullWidth />
			)}
			{get(props, "filterValues.view") === "range" && (
				<DateTimeInput source="created" alwaysOn fullWidth />
			)}
			{get(props, "filterValues.view") === "day" && (
				<DateInput source="day" alwaysOn fullWidth />
			)}
			<ReferenceInput
				{...bookmakersConfig}
				source="bookmaker_id"
				reference="bookmakers"
				sort={{ field: "name", order: "ASC" }}
				alwaysOn
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput source="match_id" reference="matches" alwaysOn>
				<AutocompleteInput optionText="fullname" />
			</ReferenceInput>
			<ReferenceInput
				source="bet_category_id"
				reference="bet-categories"
				sort={{ field: "name", order: "ASC" }}
				perPage={250}
				alwaysOn
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
		</Filter>
	);
};
export default Filters;
