import { get } from "lodash";
import React from "react";
import { ChipField, DateField, List as RaList, TextField } from "react-admin";
import Pagination from "../components/lists/Pagination";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";
import MatchBetsButton from "./MatchBetsButton";

const Datagrid = (props) => {
	const hasEventFilter = !!get(props, "filterValues.event_id");
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="name" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<DateField source="date" showTime />
					<ChipField
						source="event.name"
						label="resources.matches.fields.event_id"
					/>
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<MatchBetsButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			{!hasEventFilter && (
				<ChipField
					source="event.name"
					label="resources.matches.fields.event_id"
				/>
			)}
			<TextField source="name" style={{ fontWeight: "bold" }} />
			<DateField source="date" showTime />
			<DateField source="created" showTime />
			<DateField source="modified" showTime />
			<MatchBetsButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage()}
			sort={{ field: "date", order: "DESC" }}
			pagination={<Pagination />}
			exporter={false}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
