import React from "react";
import { DateField, EditButton, List as RaList, TextField } from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "modified", order: "desc" }}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField source="title" style={{ fontWeight: "bold" }} />
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<DateField source="date" showTime />
						<DateField source="modified" showTime />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<EditButton />
					</React.Fragment>
				}
			>
				<TextField source="id" label="ID" />
				<TextField source="title" style={{ fontWeight: "bold" }} />
				<DateField source="date" showTime />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
