import { IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { get } from "lodash";
import React, { Fragment } from "react";
import { useUpdate } from "react-admin";

const MoveButton = ({ record, source, resource, offset = 10, icon }) => {
	const index = get(record, source, 0);
	const [update, { loading }] = useUpdate(
		resource,
		record.id,
		{ [source]: index + offset },
		record,
		{
			refresh: true,
		}
	);

	return (
		<IconButton disabled={loading} onClick={update} size="small">
			{React.cloneElement(icon)}
		</IconButton>
	);
};

const OrderIndexField = ({ record, source, resource, showIndex = true }) => {
	const props = {
		record,
		source,
		resource,
	};
	return (
		<Fragment>
			{showIndex && (
				<Typography
					variant="overline"
					color="textSecondary"
					style={{ marginRight: 5, fontWeight: "bold" }}
				>
					{get(record, source, 0)}
				</Typography>
			)}
			<MoveButton {...props} offset={-1} icon={<ArrowDropUpIcon />} />
			<MoveButton {...props} offset={1} icon={<ArrowDropDownIcon />} />
		</Fragment>
	);
};
export default OrderIndexField;
