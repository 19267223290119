import { Button } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { Login, LoginForm, translate } from "react-admin";
import { compose } from "recompose";
import Logo from "../../Logo";

const styles = (theme) =>
	createStyles({
		background: {
			backgroundColor: "GhostWhite",
			backgroundImage: "none",
		},
		version: {
			position: "fixed",
			bottom: theme.spacing(1),
			right: theme.spacing(1),
		},
		button: {
			marginLeft: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginRight: theme.spacing(0.5),
		},
	});

const LoginPage = ({ classes, translate }) => (
	<Login backgroundImage="" className={classes.background}>
		<div style={{ textAlign: "center" }}>
			<Logo />
		</div>
		<div>
			<LoginForm />
			<Button
				type="button"
				color="primary"
				size="small"
				className={classes.button}
				href="#/signup"
			>
				{translate("ra.auth.signup")}
			</Button>
			<Button
				type="button"
				color="secondary"
				size="small"
				className={classes.button}
				style={{ float: "right" }}
				href="#/password-reset"
			>
				{translate("ra.auth.forgot_password")}
			</Button>
		</div>
	</Login>
);

export default compose(translate, withStyles(styles))(LoginPage);
