/* eslint-disable react/no-danger, react-hooks/exhaustive-deps */
import * as React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  IconButton,
  Badge,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  Divider,
  useMediaQuery,
  Link,
  Button,
} from "@material-ui/core";
import { NotificationsIcon } from "../icons";

import { useQuery, useTranslate } from "react-admin";
import { get } from "lodash";
import NotificationMessage from "./NotificationMessage";

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: "top right",
    marginTop: -theme.spacing(1),
  },
  list: {
    width: theme.spacing(40),
    maxHeight: theme.spacing(40),
    overflow: "auto",
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(0),
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);
  };
  const handleOpen = () =>
    isSmall
      ? (document.location.href = "#/notifications")
      : setTooltipOpen(!open);
  const stats = useQuery({
    resource: "notifications/stats",
    type: "getOne",
    payload: {
      id: "me",
    },
  });

  const unreaded = get(stats, "data.unreaded");
  const messages = get(stats, "data.notifications");

  if (isSmall) {
    return (
      <IconButton color="inherit" href="#/notifications">
        <Badge color="primary" badgeContent={unreaded}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    );
  }

  return (
    <React.Fragment>
      <Tooltip
        open={tooltipOpen}
        onOpen={handleOpen}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={translate("resources.notifications.name")}
        enterDelay={300}
      >
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={open ? "notifications-popup" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          data-ga-event-category="AppBar"
          data-ga-event-action="toggleNotifications"
        >
          <Badge color="primary" badgeContent={unreaded}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {messages.map((message, index) => (
                    <React.Fragment key={message.id}>
                      <NotificationMessage
                        onClick={handleToggle}
                        message={message}
                      />
                      {index < messages.length - 1 ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </React.Fragment>
                  ))}
                  <ListItem>
                    <Button
                      component={Link}
                      href="#/notifications"
                      onClick={handleToggle}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {translate("resources.notifications.view_all")}
                    </Button>
                  </ListItem>
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
}
