import { get } from "lodash";
import React from "react";
import {
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	NumberField,
	TextField,
} from "react-admin";
import BooleanFieldInput from "../components/fields/BooleanFieldInput";
import OrderIndexField from "../components/fields/OrderIndexField";
import TextFieldInput from "../components/fields/TextFieldInput";
import Pagination from "../components/lists/Pagination";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const Datagrid = (props) => {
	const hasMacroBetCategoryFilter = !!get(
		props,
		"filterValues.macro_bet_category_id"
	);
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="name" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<ChipField
						source="macro_bet_category.name"
						label="resources.bet-categories.fields.macro_bet_category_id"
					/>
					<NumberField source="count_bookmaker_bet_categories" />
					<DateField showTime source="modified" />
					<DateField showTime source="created" />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			{!hasMacroBetCategoryFilter && (
				<ChipField
					source="macro_bet_category.name"
					label="resources.bet-categories.fields.macro_bet_category_id"
				/>
			)}
			<TextFieldInput source="name" style={{ fontWeight: "bold" }} />
			<OrderIndexField source="order" />
			<BooleanFieldInput source="is_visible" />
			<BooleanFieldInput source="is_summary" />
			<DateField showTime source="modified" />
			<DateField showTime source="created" />
			<EditButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage()}
			sort={{ field: "order", order: "ASC" }}
			pagination={<Pagination />}
			exporter={false}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
