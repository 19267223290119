import React, { useMemo } from "react";
import {
	AutocompleteInput,
	Filter,
	ReferenceInput,
	SearchInput,
	usePermissions,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	const { permissions, loaded } = usePermissions();
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			{isAdmin && (
				<ReferenceInput source="user_id" reference="users" alwaysOn>
					<AutocompleteInput optionText="name" />
				</ReferenceInput>
			)}
		</Filter>
	);
};
export default Filters;
