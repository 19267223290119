import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import React, { useMemo } from "react";
import { SaveButton, Toolbar as RaToolbar } from "react-admin";
import BackButton from "../buttons/BackButton";
import DeleteWithConfirmButton from "../buttons/DeleteWithConfirmButton";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		"& button, & a": {
			marginRight: theme.spacing(2),
		},
		display: "flex",
		justifyContent: "space-between",
	},
}));

const ReferenceToolbar = ({
	backRedirect,
	backReferenceTarget,
	backReference,
	backTab = 1,
	canSave = true,
	canGoBack = true,
	show = false,
	...props
}) => {
	const classes = useStyles();
	const { record } = props;
	const id = get(record, "id", 0);
	const referenceId = get(record, backReferenceTarget, 0);

	const backUrl = useMemo(
		() =>
			referenceId === 0
				? backRedirect
				: backTab > 0
				? `/${backReference}/${referenceId}/${show ? "show/" : ""}${backTab}`
				: `/${backReference}/${referenceId}/${show ? "show" : ""}`,
		[backRedirect, backReference, referenceId, show, backTab]
	);
	return (
		<RaToolbar {...props} className={classes.toolbar}>
			<SaveButton redirect={backUrl} disabled={!canSave || props.pristine} />
			{canGoBack && <BackButton to={backUrl} />}
			{id > 0 && <DeleteWithConfirmButton redirect={backUrl} />}
		</RaToolbar>
	);
};

export default ReferenceToolbar;
