/**
 * npm i --save @date-io/date-fns date-fns @material-ui/pickers
 */
import DateFnsUtils from "@date-io/date-fns";
import {
	DatePicker,
	DateTimePicker,
	MuiPickersUtilsProvider,
	TimePicker,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { get } from "lodash";
import React from "react";
import { useInput, useLocale, useTranslate } from "react-admin";
import { useField } from "react-final-form";

const locales = {
	it: itLocale,
};

const Picker = ({
	source,
	label,
	helperText,
	validate,
	component,
	resource,
	alwaysOn,
	allowEmpty,
	...props
}) => {
	const {
		input: { onChange, value },
	} = useField(source);
	const {
		meta: { touched, error },
	} = useInput(source);
	const locale = useLocale();
	const translate = useTranslate();
	const properties = {
		name: source,
		value: value || null,
		onChange,
		showTodayButton: true,
		label: label || translate(`resources.${resource}.fields.${source}`),
		ampm: false,
		inputVariant: "filled",
		margin: "dense",
		cancelLabel: translate("ra.action.undo"),
		todayLabel: translate("ra.date.today"),
		required: typeof validate === "function" || typeof validate === "object",
		error: !!(touched && error),
		helperText:
			touched && error ? translate("ra.validation.required") : helperText,
		clearable: true,
		clearLabel: translate("ra.action.delete"),
		...props,
	};

	const getComponent = () => {
		switch (component) {
			case "TimePicker":
				return (
					<TimePicker
						{...properties}
						margin="dense"
						format={"dd/MM/yyyy HH:mm:ss"}
						showTodayButton={false}
						helperText={properties.helperText || " "}
					/>
				);
			case "DateTimePicker":
				return (
					<React.Fragment>
						<DateTimePicker
							{...properties}
							margin="dense"
							format={"dd/MM/yyyy HH:mm:ss"}
							helperText={properties.helperText || " "}
						/>
					</React.Fragment>
				);
			default:
				return (
					<DatePicker
						{...properties}
						margin="dense"
						format={"dd-MM-yyyy"}
						helperText={properties.helperText || " "}
					/>
				);
		}
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={get(locales, locale)}>
			{getComponent()}
		</MuiPickersUtilsProvider>
	);
};

export const DateTimeInput = (props) => (
	<Picker component="DateTimePicker" {...props} />
);
export const DateInput = (props) => (
	<Picker component="DatePicker" {...props} />
);
export const TimeInput = (props) => (
	<Picker component="TimePicker" {...props} />
);
