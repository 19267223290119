import { useMediaQuery } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CachedIcon from "@material-ui/icons/Cached";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import UserIcon from "@material-ui/icons/VerifiedUserRounded";
import React, { forwardRef } from "react";
import {
	AppBar,
	MenuItemLink,
	useCreate,
	useNotify,
	usePermissions,
	useRedirect,
	UserMenu,
	useTranslate,
} from "react-admin";
import authProvider, { IMPERSONATE_LOGOUT } from "./authProvider";
import Notifications from "./components/Notifications";
import Logo from "./Logo";
import useImpersonate from "./useImpersonate";

const useStyles = makeStyles((theme) => ({
	title: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		marginLeft: theme.spacing(1),
	},
	spacer: {
		flex: 1,
	},
	badge: {
		color: grey[100],
	},
	badgeCount: {
		color: grey[100],
		right: theme.breakpoints.down("md") ? 5 : -3,
		top: theme.breakpoints.down("md") ? 0 : 5,
	},
	[theme.breakpoints.down("md")]: {
		root: {
			"& .MuiToolbar-root": {
				paddingRight: 0,
			},
			"& .MuiIconButton-root": {
				padding: 0,
				paddingRight: theme.spacing(2),
			},
		},
	},
}));

const ConfigurationMenu = forwardRef((props, ref) => {
	const { loaded, permissions } = usePermissions();
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const impersonate = useImpersonate();
	const handleImpersonateLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();
		authProvider(IMPERSONATE_LOGOUT)
			.then(() => {
				redirect("/");
				setTimeout(() => document.location.reload(), 100);
			})
			.catch((error) => notify(error, "warning"));
	};
	const [create, { loading }] = useCreate(
		"commands",
		{
			name: "deploy",
			args: [],
			notify_args: {
				title: translate("app.deploy.notify_title"),
				content: translate("app.deploy.notify_content"),
				resource: "notifications",
			},
		},
		{
			onSuccess: () => {
				notify(translate("app.deploy.notify_success"));
			},
		}
	);
	const handleDeploy = (e) => {
		e.stopPropagation();
		e.preventDefault();
		create();
	};
	return [
		<MenuItemLink
			key={1}
			ref={ref}
			to="/profile"
			primaryText={translate("ra.auth.profile")}
			leftIcon={<UserIcon />}
			onClick={props.onClick}
		/>,
		impersonate && (
			<MenuItemLink
				key={2}
				ref={ref}
				to="/impersonate"
				leftIcon={<LogoutIcon />}
				primaryText={translate("ra.auth.impersonate_logout")}
				onClick={handleImpersonateLogout}
			/>
		),
		loaded && permissions(["admin"]) && (
			<MenuItemLink
				key={3}
				ref={ref}
				to="/deploy"
				primaryText={translate("app.deploy.title")}
				leftIcon={<CachedIcon />}
				onClick={handleDeploy}
				disabled={loading}
			/>
		),
	];
});

const CustomUserMenu = (props) => (
	<UserMenu {...props}>
		<ConfigurationMenu />
	</UserMenu>
);

const CustomAppBar = (props) => {
	const classes = useStyles();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
	return (
		<AppBar {...props} className={classes.root} userMenu={<CustomUserMenu />}>
			{isDesktop && (
				<Typography
					variant="h6"
					color="inherit"
					className={classes.title}
					id="react-admin-title"
				/>
			)}
			<Logo dark={true} />
			<span className={classes.spacer} />
			<Notifications />
		</AppBar>
	);
};

export default CustomAppBar;
