import React from "react";
import {
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useRedirect,
} from "react-admin";
import { makeRedirect } from "../components/fields/ListField";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";

// TODO: remove export
export const containBetly = (value) => {
	let url;
	try {
		url = new URL(value);
	} catch (error) {
		return "ra.validation.url";
	}
	return url.hostname === "betly.co" ? undefined : "ra.validation.betly_url";
};

const Form = (props) => {
	const { record } = props;
	const redirect = useRedirect();
	return (
		<SimpleForm
			{...props}
			initialValues={{ ...record }}
			redirect={() => redirect(makeRedirect({ ...props, tab: 2 }))}
			toolbar={
				<ReferenceToolbar
					backRedirect="/domains"
					backReference="domains"
					backReferenceTarget="domain_id"
					backTab={2}
				/>
			}
		>
			<ReferenceInput
				source="bookmaker_id"
				reference="bookmakers"
				sort={{ field: "name", order: "ASC" }}
				validate={required()}
			>
				<SelectInput />
			</ReferenceInput>
			{/* <TextInput source="url" fullWidth validate={containBetly} /> */}
			{/** TODO: Aggiungere validate={containBetly} */}
			<TextInput source="url" fullWidth />
			<TextInput source="signup_bonus" />
		</SimpleForm>
	);
};
export default Form;
