import { green } from "@material-ui/core/colors";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
export const useDarkMode = () =>
	window.matchMedia &&
	window.matchMedia("(prefers-color-scheme: dark)").matches;
export default createMuiTheme({
	type: useDarkMode() ? "dark" : "light",
	palette: {
		background: {
			default: "#EDECEC",
		},
		primary: green,
		secondary: grey,
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.3,
	},
	overrides: {
		MuiButton: {
			containedPrimary: {
				color: "#FFF",
			},
		},
	},
});
