import { withErrors } from "ra-remote-validator";
import React from "react";
import { Edit as RaEdit, useNotify, useRefresh } from "react-admin";
import { compose } from "recompose";
import { useFormStyles } from "../components/forms/useStyles";
import Title from "../components/Title";
import Form from "./Form";

const Edit = ({ dispatch, validate, errors, permissions, ...props }) => {
	const classes = useFormStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const onSuccess = (data) => {
		notify("ra.notification.updated", "info", { smart_count: 1 });
		refresh();
	};
	return (
		<RaEdit
			{...props}
			classes={classes}
			redirect={false}
			title={<Title source="email" />}
			mutationMode="pessimistic"
			onSuccess={onSuccess}
		>
			<Form
				permissions={permissions}
				dispatch={dispatch}
				validate={validate}
				errors={errors}
			/>
		</RaEdit>
	);
};
export default compose(withErrors)(Edit);
