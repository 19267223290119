import RichTextInput from "ra-input-rich-text";
import React from "react";
import { NumberInput, required, SimpleForm, TextInput } from "react-admin";
import ImageInput from "../components/inputs/ImageInput";

const Form = (props) => {
	return (
		<SimpleForm {...props} redirect="list">
			<TextInput source="title" validate={required()} fullWidth />
			<RichTextInput source="content" multline fullWidth {...props} />
			<TextInput source="url" fullWidth />
			<NumberInput source="order" />
			<ImageInput
				source="image"
				label="resources.sliders.fields.image_media_id"
			/>
		</SimpleForm>
	);
};
export default Form;
