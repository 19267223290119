import RemoteErrorsInterceptor from "ra-remote-validator";
import React from "react";
import {
	AutocompleteInput,
	BooleanInput,
	CheckboxGroupInput,
	FormTab,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import Sanitize from "../components/Sanitize";
import ReferralCodeInput from "../profile/inputs/ReferralCodeInput";
import { formatMany, parseMany } from "../utils/parsing";
import PasswordInput from "./inputs/PasswordInput";
import statuses from "./statuses";

const Form = ({
	create = false,
	dispatch,
	validate,
	errors,
	permissions,
	...props
}) => {
	const translate = useTranslate();
	return (
		<TabbedForm validate={validate} {...props}>
			<FormTab label={translate("resources.users.name", 1)}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<SelectInput source="status" choices={statuses} validate={required()} />
				<TextInput source="username" validate={required()} />
				<PasswordInput permissions={permissions} create={create} />
				<TextInput source="email" validate={required()} />
				<ReferenceInput
					source="referral_id"
					reference="users"
					filter={{ status: "active" }}
					allowEmpty={true}
				>
					<AutocompleteInput optionText="name" />
				</ReferenceInput>
				<ReferralCodeInput source="referral_code" />
				{create && (
					<Sanitize>
						<div style={{ display: "none" }}>
							<BooleanInput source="accepted_disclaimer" defaultValue={true} />
							<BooleanInput source="accepted_regulation" defaultValue={true} />
						</div>
					</Sanitize>
				)}
			</FormTab>
			<FormTab label="ra.auth.profile.title">
				<TextInput source="profile.name" validate={required()} />
				<TextInput source="profile.surname" validate={required()} />
				<TextInput source="profile.site_link" fullWidth validate={required()} />
			</FormTab>
			<FormTab label="resources.users.fields.roles">
				<ReferenceArrayInput
					source="roles"
					reference="roles"
					format={formatMany}
					parse={parseMany}
					fullWidth
				>
					<CheckboxGroupInput optionText="name" />
				</ReferenceArrayInput>
				<ReferenceArrayInput
					source="groups"
					reference="groups"
					format={formatMany}
					parse={parseMany}
					fullWidth
				>
					<CheckboxGroupInput optionText="name" />
				</ReferenceArrayInput>
			</FormTab>
		</TabbedForm>
	);
};
export default Form;
