export default [
	{
		id: ">",
		name: "resources.bookmakers.fields.bonus_quote_threshold_operator.greater_than",
	},
	{
		id: ">=",
		name: "resources.bookmakers.fields.bonus_quote_threshold_operator.greater_than_equal_to",
	},
	{
		id: "<",
		name: "resources.bookmakers.fields.bonus_quote_threshold_operator.less_than",
	},
	{
		id: "<=",
		name: "resources.bookmakers.fields.bonus_quote_threshold_operator.less_than_equal_to",
	},
	{
		id: "=",
		name: "resources.bookmakers.fields.bonus_quote_threshold_operator.equals_to",
	},
];
