import React from "react";
import { Create as RaCreate, useNotify, useRedirect } from "react-admin";
import { useFormStyles } from "../components/forms/useStyles";
import Form from "./Form";

const Create = (props) => {
	const classes = useFormStyles();
	const redirect = useRedirect();
	const notify = useNotify();
	const onSuccess = (data) => {
		notify("ra.notification.created", "info");
		redirect("edit", "/events", data.id, data);
	};
	return (
		<RaCreate {...props} classes={classes}>
			<Form create={true} onSuccess={onSuccess} />
		</RaCreate>
	);
};
export default Create;
