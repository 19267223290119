import { UserIcon } from "../icons";
import List from "./List";

export default {
	options: {
		group: "dashboard",
		roles: ["referral"],
	},
	icon: UserIcon,
	list: List,
	create: () => {},
};
