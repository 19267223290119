import React from "react";
import {
  DateField,
  EmailField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Empty from "./Empty";
import Filters from "./Filters";

const List = (props) => {
  const classes = useListStyles();
  const translate = useTranslate();
  return (
    <RaList
      {...props}
      perPage={perPage(50, 25)}
      filters={<Filters />}
      sort={{ field: "created", order: "DESC" }}
      actions={false}
      bulkActionButtons={false}
      empty={<Empty />}
      classes={classes}
      title={translate("resources.referral-users.name", 0)}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <TextField
              source="name"
              label="resources.users.fields.profile.name"
              style={{ fontWeight: "bold" }}
              variant="h6"
            />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <EmailField source="email" />
            <DateField source="created" showTime color="textSecondary" />
          </React.Fragment>
        }
      >
        <TextField
          source="name"
          label="resources.users.fields.profile.name"
          style={{ fontWeight: "bold" }}
        />
        <EmailField source="email" />
        <DateField source="created" showTime />
      </ResponsiveList>
    </RaList>
  );
};

export default List;
