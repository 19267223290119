import React, { useState } from "react";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Button, useNotify, useRedirect, useTranslate } from "react-admin";
import authProvider, { IMPERSONATE } from "../authProvider";

const LoginButton = ({
  basePath = "",
  label = "ra.auth.sign_in",
  record,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    authProvider(IMPERSONATE, { id: record.id })
      .then(() => {
        setLoading(false);
        notify(translate("ra.auth.sign_in_success"), "success");
        redirect("/");
        setTimeout(() => window.location.reload(), 200);
      })
      .catch(() => {
        setLoading(false);
        notify(translate("ra.auth.sign_in_error"), "warning");
      });
  };

  return (
    <Button
      disabled={loading}
      color="secondary"
      label={label}
      onClick={handleClick}
      {...rest}
    >
      <VpnKeyIcon />
    </Button>
  );
};

export default LoginButton;
