import { get } from "lodash";
import moment from "moment";
import React from "react";
import { SelectInput, useTranslate } from "react-admin";
import { capitalize } from "underscore.string";

const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_RANGE_EXPR = {
	today: () => ({
		start_at: moment().format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	yesterday: () => ({
		start_at: moment().subtract(1, "days").format(DATE_FORMAT),
		end_at: moment().subtract(1, "days").format(DATE_FORMAT),
		day: null,
	}),
	last_7_days: () => ({
		start_at: moment().subtract(7, "days").format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	last_30_days: () => ({
		start_at: moment().subtract(30, "days").format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	prev_week: () => ({
		start_at: moment()
			.subtract(1, "weeks")
			.startOf("isoWeek")
			.format(DATE_FORMAT),
		end_at: moment().subtract(1, "weeks").endOf("isoWeek").format(DATE_FORMAT),
		day: null,
	}),
	this_month: () => ({
		name: (props, translate) =>
			translate(`date_ranges.${props.source}.date_range.this_month`, {
				month: moment().format("MMMM"),
			}),
		start_at: moment().startOf("month").format(DATE_FORMAT),
		end_at: moment().endOf("month").format(DATE_FORMAT),
		day: null,
	}),
	// prev_month: () => ({
	//   name: (props, translate) =>
	//     translate(`${props.resource}.${props.source}.date_range.prev_month`, {
	//       month: capitalize(moment().format("MMMM")),
	//     }),
	//   start_at: moment()
	//     .subtract(1, "months")
	//     .startOf("month")
	//     .format(DATE_FORMAT),
	//   end_at: moment().subtract(1, "months").endOf("month").format(DATE_FORMAT),
	//   day: null,
	// }),
	last_month: () => ({
		name: (props, translate) =>
			translate(`date_ranges.${props.source}.date_range.last_month`, {
				month: capitalize(moment().subtract(1, "months").format("MMMM")),
			}),
		start_at: moment()
			.subtract(1, "months")
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment().subtract(1, "months").endOf("month").format(DATE_FORMAT),
		day: null,
	}),
	last_two_months: () => ({
		name: (props, translate) =>
			translate(`date_ranges.${props.source}.date_range.last_two_months`, {
				from: capitalize(moment().subtract(1, "months").format("MMMM")),
				to: capitalize(moment().format("MMMM")),
			}),
		start_at: moment()
			.subtract(1, "months")
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment().endOf("month").format(DATE_FORMAT),
		day: null,
	}),
	range: () => ({
		start_at: moment().startOf("month").format(DATE_FORMAT),
		end_at: moment().endOf("month").format(DATE_FORMAT),
		day: null,
	}),
	day: () => ({
		start_at: null,
		end_at: null,
		day: moment().format(DATE_FORMAT),
	}),
};
const DATE_RANGES = (props, translate) =>
	Object.keys(DATE_RANGE_EXPR).map((id) => {
		const nameResolver = (props, translate) =>
			translate(`date_ranges.${props.source}.date_range.${id}`);
		const expr = get(DATE_RANGE_EXPR, `${id}`, () => ({
			name: nameResolver,
		}))();
		const name = get(expr, "name", nameResolver)(props, translate);
		return {
			id,
			name,
		};
	});

const DateRangeInput = ({
	displayedFilters,
	filterValues,
	setFilters,
	...props
}) => {
	const translate = useTranslate();

	const handleChange = (evt) => {
		const value = evt.target.value;
		const range = get(DATE_RANGE_EXPR, value, get(DATE_RANGE_EXPR, "today"))();

		const newFilters = {
			...filterValues,
			[props.source]: value,
			...range,
		};
		setFilters(newFilters, displayedFilters);
	};
	return (
		<SelectInput
			{...props}
			label={translate(`app.report.view_range`)}
			choices={DATE_RANGES(props, translate)}
			onChange={handleChange}
		/>
	);
};

export default DateRangeInput;
