import { get } from "lodash";
import React from "react";
import { DateTimeInput, Filter, SearchInput } from "react-admin";
import { DateInput } from "../components/inputs/DatePickersInput";
import DateRangeInput from "../components/inputs/DateRangeInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<DateRangeInput
				source="view"
				displayedFilters={props.displayedFilters}
				filterValues={props.filterValues}
				setFilters={props.setFilters}
				alwaysOn
				fullWidth
				style={{ marginTop: 32 }}
			/>
			{get(props, "filterValues.view") === "range" && (
				<DateTimeInput source="start_at" alwaysOn fullWidth />
			)}
			{get(props, "filterValues.view") === "range" && (
				<DateTimeInput source="end_at" alwaysOn fullWidth />
			)}
			{get(props, "filterValues.view") === "day" && (
				<DateInput source="day" alwaysOn fullWidth />
			)}
		</Filter>
	);
};
export default Filters;
