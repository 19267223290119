import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import {
	AutocompleteInput,
	Filter,
	ReferenceInput,
	SelectInput,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import { usersConfig } from "../users";
import statuses from "./statuses";

const Filters = (props) => {
	const classes = useFiltersStyles();
	const theme = useTheme();
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<Filter {...props} classes={classes}>
			<ReferenceInput
				source="user_id"
				reference="users"
				alwaysOn={!sm}
				fullWidth
				style={{ marginTop: theme.spacing(4) }}
				{...usersConfig.reference}
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<SelectInput
				source="status"
				choices={statuses}
				optionText="name"
				alwaysOn
				resettable
				fullWidth
			/>
		</Filter>
	);
};
export default Filters;
