import { get } from "lodash";
import React from "react";
import { ChipField, DateField, TextField, UrlField } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const ItemsField = ({ planned, ...props }) => {
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			reference="domain-items"
			target="domain_id"
			sort={{ field: "created", order: "DESC" }}
			useLabel={false}
			removeRedirect={makeRedirect({ ...props, tab: 2 })}
		>
			<TextField label="ID" source="id" />
			<ChipField
				source="bookmaker.name"
				label="resources.domain-items.fields.bookmaker_id"
			/>
			<UrlField source="url" />
			<DateField source="created" showTime />
		</ListField>
	);
};

ItemsField.defaultProps = {
	canModify: true,
	canCreate: true,
	canRemove: true,
};

export default ItemsField;
