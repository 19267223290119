import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import { get, set } from "lodash";
import React, { useState } from "react";
import {
  Notification,
  Title,
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import CheckboxInput from "../../components/pages/input/CheckboxInput";
import SelectInput from "../../components/pages/input/SelectInput";
import TextInput from "../../components/pages/input/TextInput";
import taxTypes from "../../profile/tax_types";
import * as Validation from "../../utils/validation";

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "GhostWhite",
  },
  form: {
    minWidth: 300,
    maxWidth: "100%",
    width: 500,
    marginTop: "6em",
  },
  header: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: "column",
    margin: theme.spacing(1),
    "& > :not(:first-child)": {
      marginLeft: 0,
    },
  },
  button: {
    display: "block",
    clear: "both",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  link: {
    width: "auto",
    alignSelf: "flex-start",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);
const SignupPage = ({ ...rest }) => {
  const search = get(rest, "location.search", "");
  const params = new URLSearchParams(search);
  const [state, setState] = useState({
    user: {
      profile: {
        name: "",
        surname: "",
        tax_type: "",
        vat_code: "",
        site_link: "",
      },
      profile_logs: "",
      email: "",
      username: "",
      password: "",
      confirm_password: "",
      accepted_disclaimer: false,
      accepted_regulation: false,
      referral_code: params.has("referral_code")
        ? params.get("referral_code")
        : null,
    },
    loading: false,
    errors: {},
  });
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();

  const change = (name, value, error) => {
    const errors = { ...state.errors, [name]: error };
    setState({
      ...state,
      user: set(state.user, name, value),
      errors: Validation.mapErrors(errors, name, error),
    });
  };
  const props = (props, errors = {}) => ({
    change,
    record: state.user,
    validate: Validation.required,
    error: errors[props.name] || null,
    ...props,
  });

  const field = (
    name,
    validate = Validation.required,
    Input = TextInput,
    type = undefined,
    props = {}
  ) => ({
    name,
    Input,
    props: { validate, name, type, prefix: "ra.auth.signup.fields", ...props },
  });

  const taxType = get(state, "user.profile.tax_type", null);

  const fields = [
    field("profile.name"),
    field("profile.surname"),
    field("profile.tax_type", Validation.required, SelectInput, undefined, {
      options: taxTypes.map((tt) => ({
        label: translate(tt.name),
        value: tt.id,
      })),
    }),
    field("profile.vat_code", Validation.required, TextInput, "text", {
      params: taxType ? `.${taxType}` : "",
    }),
    field("email", Validation.email, TextInput, "email"),
    field("password", Validation.required, TextInput, "password"),
    field("confirm_password", Validation.required, TextInput, "password"),
    field("profile.site_link", Validation.url, TextInput),
    field("accepted_regulation", Validation.checked, CheckboxInput, undefined, {
      label: (
        <span>
          {translate("ra.auth.signup.fields.accepted_regulation")}&nbsp;
          <a href="/pages/tos" target="_blank" rel="noopener noreferrer">
            {translate("ra.auth.signup.fields.accepted_regulation.rule")}
          </a>
        </span>
      ),
    }),
    field("accepted_disclaimer", Validation.checked, CheckboxInput, undefined, {
      label: (
        <span>
          {translate("ra.auth.signup.fields.accepted_disclaimer")}&nbsp;
          <a
            href="/pages/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate(
              "ra.auth.signup.fields.accepted_disclaimer.privacy_policy"
            )}
          </a>
        </span>
      ),
    }),
  ].filter((field) => field !== undefined);

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setState({ ...state, loading: true });
    const errors = Validation.validate(state.user, fields, translate);
    if (Validation.hasErrors(errors)) {
      setState({
        ...state,
        errors,
      });
      return;
    }

    dataProvider
      .create("users", { data: state.user })
      .then((response) => {
        notify(translate("ra.auth.signup.success"));
        redirect("/login");
      })
      .catch(
        ({
          message,
          body: {
            data: { code, errors },
          },
        }) => {
          if (code === 422) {
            setState({
              ...state,
              loading: false,
              errors: Validation.mapRemoteErrors(errors),
            });
            notify(message, "wargnin");
          } else {
            notify(message, "warning");
          }
        }
      );
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={submit}>
        <Card>
          <Title title={translate("ra.auth.signup.title")} />
          <div className={classes.header}>
            <Avatar>
              <VerifiedUserRoundedIcon />
            </Avatar>
          </div>
          <CardContent>
            {fields.map((field) => (
              <React.Fragment key={field.name}>
                {field.Input && (
                  <field.Input {...props(field.props, state.errors)} />
                )}
              </React.Fragment>
            ))}
          </CardContent>
          <CardActions className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              disabled={state.loading || Validation.hasErrors(state.errors)}
            >
              {state.loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate("ra.auth.signup.button")}
            </Button>

            <Button className={classes.link} href="/#/login" color="primary">
              &larr;
              {translate("ra.auth.back_to_login")}
            </Button>
          </CardActions>
        </Card>
      </form>
      <Notification />
    </div>
  );
};

export default SignupPage;
