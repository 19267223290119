import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	makeStyles,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import classnames from "classnames";
import { get } from "lodash";
import * as React from "react";
import { useTranslate } from "react-admin";
const styles = (theme) => ({
	formInputCheckbox: {
		display: "block",
		clear: "both",
	},
	formInputCheckboxWithErrors: {
		color: red[500],
	},
});

const useStyles = makeStyles(styles);

const CheckboxInput = ({
	prefix = "",
	name,
	label,
	record,
	error,
	change,
	validate,
	params = {},
}) => {
	const [valid, setValid] = React.useState(true);
	const translate = useTranslate();
	const classes = useStyles();
	const handleChange = (evt) => {
		let value = evt.target.checked;
		let valid = validate ? validate(value) : true;
		setValid(valid);
		change(name, value, valid && valid !== true);
	};
	const hasErrors = (valid && valid !== true) || (error && error !== false);
	return (
		<FormControl
			className={classnames(
				classes.formInputCheckbox,
				hasErrors && classes.formInputCheckboxWithErrors
			)}
			error={hasErrors}
		>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							className={classes.formInputCheckbox}
							value={get(record, name) || false}
							name={name}
							onChange={handleChange}
						/>
					}
					label={label || translate(`${prefix}.${name}`, params)}
				/>
			</FormGroup>
			<FormHelperText>
				{valid && valid !== true ? translate(valid) : error || null}
			</FormHelperText>
		</FormControl>
	);
};
export default CheckboxInput;
