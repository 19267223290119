import React from "react";
import {
	BooleanInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";

const Form = ({ toolbar, create, ...props }) => {
	return (
		<SimpleForm {...props} redirect="list">
			<TextInput source="name" validate={required()} fullWidth />
			{create && <TextInput source="code" validate={required()} />}
			<ReferenceInput
				source="macro_bet_category_id"
				reference="macro-bet-categories"
				sort={{ order: "ASC", field: "order" }}
			>
				<SelectInput />
			</ReferenceInput>
			<BooleanInput source="is_visible" />
			<BooleanInput source="is_summary" />
		</SimpleForm>
	);
};
export default Form;
