import { useMediaQuery } from "@material-ui/core";
import React from "react";
import {
	BooleanInput,
	Filter as RaFilter,
	ReferenceInput,
	SearchInput,
	SelectInput,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import statuses from "./statuses";

const Filters = (props) => {
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<RaFilter {...props} classes={classes}>
			<SearchInput source="q" alwaysOn fullWidth />
			<SelectInput
				source="status"
				choices={statuses}
				alwaysOn={!sm}
				fullWidth
			/>
			<ReferenceInput
				source="role_id"
				reference="roles"
				alwaysOn={!sm}
				fullWidth
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<BooleanInput source="has_children" alwaysOn={!sm} fullWidth />
		</RaFilter>
	);
};
export default Filters;
