import { get } from "lodash";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Edit as RaEdit,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar as RaToolbar,
	useNotify,
	usePermissions,
	useRefresh,
} from "react-admin";
import { compose } from "recompose";
import { useFormStyles } from "../components/forms/useStyles";
import Title from "../components/Title";
import PasswordInput from "../users/inputs/PasswordInput";
import PaymentMethodsInput from "../users/inputs/PaymentMethodInput";
import ReferralCodeInput from "./inputs/ReferralCodeInput";
import TaxInput from "./inputs/TaxInput";

const Toolbar = (props) => (
	<RaToolbar {...props}>
		<SaveButton />
	</RaToolbar>
);

const Edit = ({ dispatch, validate, errors, staticContext, ...props }) => {
	const { permissions } = usePermissions();
	const classes = useFormStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	return (
		<RaEdit
			id="profile"
			resource="users"
			basePath="profile"
			redirect=""
			title={<Title source="email" />}
			{...props}
			classes={classes}
			onSuccess={(data) => {
				const isAdmin = get(data, "data.is_admin", false);
				if (!isAdmin) {
					notify("resources.user-profiles.user_on_success");
				}
				refresh();
			}}
		>
			<SimpleForm validate={validate} toolbar={<Toolbar />}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<TextInput source="email" validate={required()} />
				<PasswordInput permissions={permissions} />
				<TextInput source="profile.name" validate={required()} />
				<TextInput source="profile.surname" validate={required()} />
				<ReferralCodeInput source="referral_code" readOnly />
				<TextInput source="profile.site_link" fullWidth validate={required()} />
				<TaxInput source="profile." fullWidth />
				<PaymentMethodsInput source="profile.payment_method" />
			</SimpleForm>
		</RaEdit>
	);
};

export default compose(withErrors)(Edit);
