import { makeStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert, AlertTitle } from "@material-ui/lab";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiAlert-message": {
			display: "block",
		},
		marginBottom: theme.spacing(2),
	},
}));

const AlertField = ({ source, record, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const isValid = get(record, source, false);
	if (isValid) {
		return false;
	}
	return (
		<Alert severity="warning" icon={<WarningIcon />} className={classes.root}>
			<AlertTitle>
				<strong>{translate("resources.domains.errors.is_valid.title")}</strong>
			</AlertTitle>
			{translate("resources.domains.errors.is_valid.content", record)}
		</Alert>
	);
};

export default AlertField;
