import { get } from "lodash";
export const TOGGLE_HELP_BLOCK = 'TOGGLE_HELP_BLOCK"';
export default (
  previousState = {
    blocks: {},
  },
  { type, payload }
) => {
  if (type === TOGGLE_HELP_BLOCK) {
    const { url } = payload;
    return {
      ...previousState,
      blocks: {
        ...previousState.blocks,
        [url]: !get(previousState, `blocks.${url}`, true),
      },
    };
  }
  return previousState;
};
