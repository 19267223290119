export default [
	{
		id: "person",
		name: "resources.profiles.tax_type.person",
	},
	{
		id: "company",
		name: "resources.profiles.tax_type.company",
	},
];
