import { Chip, Menu, MenuItem } from "@material-ui/core";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import PanToolIcon from "@material-ui/icons/PanTool";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { get } from "lodash";
import React, { useState } from "react";
import {
	useDataProvider,
	useNotify,
	useRefresh,
	useTranslate,
} from "react-admin";
import statuses from "../statuses";

const STATUS_COLOR_MAP = {
	pending: grey[500],
	active: green[500],
	banned: red[500],
	suspended: yellow[500],
};

const STATUS_ICON_MAP = {
	pending: PanToolIcon,
	active: CheckIcon,
	banned: BlockIcon,
	suspended: PauseCircleFilledIcon,
};

const StatusField = ({ source, record }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const status = get(record, source);
	const refresh = useRefresh();

	const Icon = STATUS_ICON_MAP[status || "pending"] || null;
	const color = STATUS_COLOR_MAP[status || "pending"];

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const handleChange = (status) => {
		setAnchorEl(null);
		dataProvider
			.update("users/change-status", {
				data: {
					id: record.id,
					status: status,
				},
			})
			.then(({ success, data }) => {
				refresh();
				notify(translate("resources.users.messages.status_changed"));
			})
			.catch(({ error: { message } }) => notify(message, "warning"));
	};

	return (
		<>
			<Chip
				icon={<Icon style={{ color: "white" }} />}
				onClick={handleClick}
				style={{
					color: "white",
					cursor: "pointer",
					backgroundColor: color,
				}}
				label={translate(`resources.users.status.${status}`)}
			/>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{statuses.map((statusItem) => (
					<MenuItem
						onClick={handleChange.bind(this, statusItem.id)}
						key={statusItem.id}
						style={{
							borderLeft: 2,
							borderLeftColor: STATUS_COLOR_MAP[statusItem.id],
							borderLeftStyle: "solid",
							backgroundColor: status === statusItem.id ? grey[100] : "white",
						}}
					>
						{translate(`resources.users.status.${statusItem.id}`)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
export default StatusField;
