import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
	BooleanInput,
	FormTab,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import ImageInput from "./inputs/ImageInput";

const Form = (props) => {
	const translate = useTranslate();
	return (
		<TabbedForm {...props} redirect="list">
			<FormTab label={translate("resources.pages.name", 1)}>
				<ReferenceInput
					source="language_id"
					reference="languages"
					validate={required()}
					sort={{ field: "name", order: "ASC" }}
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
				<TextInput source="code" validate={required()} />
				<TextInput source="name" validate={required()} />
				<RichTextInput source="content" multline fullWidth {...props} />
				<ImageInput source="image" />
			</FormTab>
			<FormTab label="resources.pages.settings">
				<BooleanInput source="is_menu" />
				<NumberInput source="order" />
			</FormTab>
			<FormTab label="resources.pages.og">
				<TextInput source="og_title" />
				<ImageInput source="og_image" />
			</FormTab>
		</TabbedForm>
	);
};
export default Form;
