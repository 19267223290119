import { get } from "lodash";
import React from "react";
import {
	ChipField,
	EditButton,
	List as RaList,
	NumberField,
	SelectField,
	TextField,
	useTranslate,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import CloneButton from "./buttons/CloneButton";
import Filters from "./Filters";
import types from "./types";

const Datagrid = (props) => {
	const hasBookmakerFilter = !!get(props, "filterValues.bookmaker_id");
	const hasTypeFilter = !!get(props, "filterValues.type");
	const translate = useTranslate();
	return (
		<ResponsiveList
			{...props}
			mobileBody={
				<React.Fragment>
					{!hasBookmakerFilter && (
						<ChipField
							style={{ marginLeft: 0 }}
							source="bookmaker.name"
							label={translate("resources.bookmakers.name", 1)}
						/>
					)}
					{!hasTypeFilter && (
						<SelectField source="type" variant="overline" choices={types}>
							<TextField />
						</SelectField>
					)}
					<NumberField
						source="from"
						style={{ fontWeight: "bold" }}
						variant="h5"
					/>
					<NumberField
						source="to"
						style={{ fontWeight: "bold" }}
						variant="h5"
					/>
					<NumberField
						source="value_percent"
						label="resources.bookmaker-bonuses.fields.value"
						style={{ fontWeight: "bold" }}
						variant="h5"
						options={{ style: "percent" }}
					/>
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
					<CloneButton />
				</React.Fragment>
			}
		>
			{!hasBookmakerFilter && (
				<ChipField
					style={{ marginLeft: 0 }}
					source="bookmaker.name"
					label={translate("resources.bookmakers.name", 1)}
				/>
			)}
			{!hasTypeFilter && (
				<SelectField source="type" variant="overline" choices={types}>
					<TextField />
				</SelectField>
			)}
			<NumberField source="from" style={{ fontWeight: "bold" }} variant="h5" />
			<NumberField source="to" style={{ fontWeight: "bold" }} variant="h5" />
			<NumberField
				source="value_percent"
				label="resources.bookmaker-bonuses.fields.value"
				style={{ fontWeight: "bold" }}
				variant="h5"
				options={{ style: "percent" }}
			/>
			<EditButton />
			<CloneButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "from", order: "ASC" }}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
