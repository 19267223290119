import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { ImageField, TextField } from "react-admin";

const useStyles = makeStyles(() => ({
	image: {
		width: 120,
		height: 80,
		objectFit: "cover",
	},
}));

const NameOrLogoField = ({ source, record, basePath, ...props }) => {
	const classes = useStyles();
	const logo = get(record, "logo.file.path");
	if (logo) {
		return <ImageField source="logo.file.path" {...props} classes={classes} />;
	}
	return <TextField source={source} {...props} />;
};
export default NameOrLogoField;
