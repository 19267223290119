import {
	FormControl,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
} from "@material-ui/core";
import { get } from "lodash";
import * as React from "react";
import { useTranslate } from "react-admin";

const styles = (theme) => ({
	formInput: {
		clear: "both",
		display: "block",
		width: "100%",
		minWidth: 120,
		marginBottom: theme.spacing(2),
	},
});
const useStyles = makeStyles(styles);
const SelectInput = ({
	prefix = "",
	name,
	record,
	error,
	change,
	validate,
	options = [],
}) => {
	const [valid, setValid] = React.useState(true);
	const translate = useTranslate();
	const classes = useStyles();
	const handleChange = (evt) => {
		let value = evt.target.value;
		let valid = validate ? validate(value) : true;
		setValid(valid);
		change(name, value, valid && valid !== true);
	};
	const hasErrors = (valid && valid !== true) || (error && error !== false);
	return (
		<FormControl error={hasErrors} className={classes.formInput}>
			<InputLabel>{translate(`${prefix}.${name}`)}</InputLabel>
			<Select fullWidth value={get(record, name) || ""} onChange={handleChange}>
				{options.map((option, index) => (
					<MenuItem key={index} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
export default SelectInput;
