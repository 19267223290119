import { CardActionArea, CardActions, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslate } from "react-admin";

const useListStyles = makeStyles((theme) => ({
	[theme.breakpoints.down("xs")]: {
		root: { margin: "1em" },
	},
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		margin: "0.5rem 0",
		marginBottom: theme.spacing(2),
	},
	cardHeader: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	cardTitleContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	cardContent: {
		borderTop: `1px solid ${theme.palette.background.default}`,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	// cardContentRow: {
	// 	display: "flex",
	// 	flexDirection: "row",
	// 	alignItems: "center",
	// 	margin: "0.5rem 0",
	// },
	cardContentRow: {
		margin: "0.5rem 0",
	},
	cardActions: {
		borderTop: `1px solid ${theme.palette.background.default}`,
	},
}));

const Mobilegrid = ({
	ids,
	data,
	basePath,
	resource,
	children,
	header,
	actions,
	displayColumnName,
	...props
}) => {
	const classes = useListStyles();
	const translate = useTranslate();

	if (!ids || !data || !basePath) {
		return null;
	}

	return (
		<div className={classes.root}>
			{ids.map((id) => (
				<Card key={id} className={classes.card} elevation={4}>
					<CardHeader
						title={
							React.isValidElement(header) && (
								<div className={classes.cardTitleContent}>
									{React.Children.map(header.props.children, (field, index) =>
										React.isValidElement(field) ? (
											<span className={classes.cardContentRow}>
												{React.cloneElement(field, {
													basePath,
													key: field.props.source || index,
													id: String(id),
													resource,
													record: data[id],
												})}
											</span>
										) : null
									)}
								</div>
							)
						}
						className={classes.cardHeader}
					/>
					{React.Children.count(children) > 0 && (
						<CardActionArea>
							<CardContent className={classes.cardContent}>
								{React.Children.map(children, (field, index) =>
									React.isValidElement(field) ? (
										<div className={classes.cardContentRow}>
											{displayColumnName && (
												<Typography
													component="div"
													variant="overline"
													gutterBottom
													style={{ fontWeight: "bold" }}
												>
													{translate(
														`resources.${resource}.fields.${field.props.source}`
													)}
													:
												</Typography>
											)}
											<div>
												{React.cloneElement(field, {
													basePath,
													key: field.props.source || index,
													id: String(id),
													resource,
													record: data[id],
												})}
											</div>
										</div>
									) : null
								)}
							</CardContent>
						</CardActionArea>
					)}
					{React.isValidElement(actions) && (
						<CardActions className={classes.cardActions}>
							{React.Children.map(actions.props.children, (field, index) =>
								React.isValidElement(field) ? (
									<span>
										{React.cloneElement(field, {
											basePath,
											key: field.props.source || index,
											id: String(id),
											resource,
											record: data[id],
										})}
									</span>
								) : null
							)}
						</CardActions>
					)}
				</Card>
			))}
		</div>
	);
};

Mobilegrid.propTypes = {
	basePath: PropTypes.string,
	children: PropTypes.node.isRequired,
	data: PropTypes.object,
	ids: PropTypes.arrayOf(PropTypes.any),
	resource: PropTypes.string,
	displayColumnName: PropTypes.bool,
};

Mobilegrid.defaultProps = {
	data: {},
	ids: [],
	displayColumnName: true,
};

export default Mobilegrid;
