export default [
	{
		id: "multiple",
		name: "resources.bookmaker-bonuses.fields.type.multiple",
	},
	{
		id: "system",
		name: "resources.bookmaker-bonuses.fields.type.system",
	},
];
