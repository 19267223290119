import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";
const ApprovalDialog = ({ record, onCloseRequest }) => {
	const translate = useTranslate();
	const handleClose = () => onCloseRequest();
	const requestedField = get(record, "requested_field", {});
	return (
		<Dialog open={record !== null} maxWidth="sm" fullWidth>
			<DialogTitle>
				{translate("resources.user-profile-approvals.titles.view")}
			</DialogTitle>
			<DialogContent>
				{Object.keys(requestedField).map((field) => (
					<React.Fragment key={field}>
						<Typography variant="overline" style={{ fontWeight: "bold" }}>
							{translate(`resources.user-profiles.fields.${field}`)}
						</Typography>
						<Typography variant="body1">
							{field === "tax_type"
								? translate(
										`resources.profiles.tax_type.${get(
											requestedField,
											field,
											""
										)}`
								  )
								: get(requestedField, field, "")}
						</Typography>
						<br />
					</React.Fragment>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{translate("ra.action.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ApprovalDialog;
