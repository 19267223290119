import { Grid } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { required, SelectInput, TextInput } from "react-admin";
import { useFormState } from "react-final-form";
import taxTypes from "../tax_types";

const TaxInput = ({ source, ...props }) => {
  const formState = useFormState();
  const taxType = get(formState.values, `${source}tax_type`, null);
  return (
    <Grid container>
      <SelectInput
        source={`${source}tax_type`}
        choices={taxTypes}
        validate={required()}
        {...props}
      />
      {taxType && (
        <TextInput
          source={`${source}vat_code`}
          label={`resources.users.fields.profile.vat_code_${taxType}`}
          validate={required()}
          {...props}
        />
      )}
    </Grid>
  );
};

export default TaxInput;
