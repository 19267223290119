import WarningIcon from "@material-ui/icons/Warning";
import React, { useMemo } from "react";
import {
	BooleanField,
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	NumberField,
	TextField,
	usePermissions,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/per-page";
import Filters from "./Filters";

const List = (props) => {
	const classes = useListStyles();
	const { permissions, loaded } = usePermissions();
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
			sort={{ field: "order", order: "ASC" }}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField
							source="name"
							label="resources.domains.fields.domain_name"
							style={{ fontWeight: "bold" }}
						/>
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<TextField source="id" label="ID" />
						<BooleanField source="is_valid" FalseIcon={WarningIcon} />
						<NumberField source="count_bookmakers" />
						<NumberField source="remaining_bookmakers" />
						{isAdmin && (
							<ChipField
								source="user.name"
								label="resources.domains.fields.user_id"
							/>
						)}
						<DateField source="created" showTime />
					</React.Fragment>
				}
				mobileActions={
					<React.Fragment>
						<EditButton />
					</React.Fragment>
				}
			>
				<TextField source="id" label="ID" />
				<TextField
					source="name"
					label="resources.domains.fields.domain_name"
					style={{ fontWeight: "bold" }}
				/>
				<BooleanField source="is_valid" FalseIcon={WarningIcon} />
				<NumberField source="count_bookmakers" />
				<NumberField source="remaining_bookmakers" />
				{isAdmin && (
					<ChipField
						source="user.name"
						label="resources.domains.fields.user_id"
					/>
				)}
				<DateField source="created" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
