import { stringify } from "query-string";
import getHeaders from "./authHeaders";
import { API_URL } from "./config";
import { fetchJson } from "./fetch";

/**
 * Il problema è molto serio: 2 giorni di debugging per capire che l'ultima release di react-admin
 * e, probabilmente, la dipendenza final-form/react-final-form hanno introdotto un bug sulla gestione degli
 * array per cui il merge tra dati in input e dati ricevuti nella gestione FORM esegue esattamente un merge
 * fisico tra oggetti per chiave "id" generando SEMPRE oggetti con tutte le props della many ref NULL.
 *
 * Vaffanculo, 2 giorni, ho detto tutto, questo è il risultato (guarda CREATE/UPDATE in questo file
 * per capire l'applicazione di questo codice).
 *
 * @param {Object} data
 */
const prepareData = (data) => {
	let newData = Object.keys(data).reduce(
		(r, key) => ({
			...r,
			[key]: Array.isArray(data[key])
				? data[key].map((item) => {
						if (item._joinData === null) {
							return { id: item.id };
						}
						return item;
				  })
				: data[key],
		}),
		{}
	);

	return newData;
};

const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.rawFile);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

const convertMany = (data, propertyName) => {
	if (
		data &&
		data[propertyName] &&
		data[propertyName].length &&
		data[propertyName].length > 0
	) {
		return Promise.all(
			data[propertyName].map((file) =>
				file.rawFile
					? convertFileToBase64(file).then((convertedFile) => ({
							data: convertedFile,
							name: file.rawFile.name,
							size: file.rawFile.size,
							type: file.rawFile.type,
					  }))
					: file
			)
		);
	}
	return Promise.resolve([]);
};

const convertOne = (data, propertyName) => {
	if (
		data &&
		data[propertyName] &&
		data[propertyName].rawFile &&
		data[propertyName].rawFile instanceof File
	) {
		return convertFileToBase64(data[propertyName]).then((convertedFile) => ({
			data: convertedFile,
			name: data[propertyName].rawFile.name,
			size: data[propertyName].rawFile.size,
			type: data[propertyName].rawFile.type,
		}));
	}
	return Promise.resolve(data[propertyName]);
};

const MANY_PROPS = ["docs"];
const ONE_PROPS = [
	"cover",
	"favicon",
	"logo",
	"logo_transparent",
	"csv",
	"bc_logo",
	"og_image",
	"image",
	"invoice",
];

async function prepareUpload(data) {
	if (data === undefined || data === null) {
		return;
	}
	for (var manyIndex = 0; manyIndex < MANY_PROPS.length; manyIndex++) {
		let array = data[MANY_PROPS[manyIndex]];
		if (array === undefined) {
			continue;
		}
		data[MANY_PROPS[manyIndex]] = await convertMany(
			data,
			MANY_PROPS[manyIndex]
		);
	}
	for (var oneIndex = 0; oneIndex < ONE_PROPS.length; oneIndex++) {
		let one = data[ONE_PROPS[oneIndex]];

		if (one === undefined) {
			continue;
		}
		data[ONE_PROPS[oneIndex]] = await convertOne(data, ONE_PROPS[oneIndex]);
	}
	let otherArrays = Object.keys(data).filter(
		(k) =>
			data[k] !== undefined &&
			Array.isArray(data[k]) &&
			MANY_PROPS.indexOf(k) === -1 &&
			ONE_PROPS.indexOf(k) === -1
	);

	for (var arrayIndex = 0; arrayIndex < otherArrays.length; arrayIndex++) {
		for (var i = 0; i < data[otherArrays[arrayIndex]].length; i++) {
			data[otherArrays[arrayIndex]][i] = await prepareUpload(
				data[otherArrays[arrayIndex]][i]
			);
		}
	}
	return data;
}

const dataProvider = {
	getInfo: (resource, params) => {
		const url = `${API_URL}/${resource}/info?${stringify(params)}`;
		const options = { headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json.data,
		}));
	},
	getBadges: () => {
		const url = `${API_URL}/stats/badges`;
		const options = { headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json,
		}));
	},
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;

		const filter = Object.keys(params.filter || {}).reduce(
			(f, filterName) => ({
				...f,
				[filterName]:
					params.filter[filterName] instanceof Array
						? params.filter[filterName].join(",")
						: params.filter[filterName],
			}),
			{}
		);

		const query = {
			sort: field,
			direction: order,
			page: page,
			limit: perPage,
			...filter,
		};
		const url = `${API_URL}/${resource}?${stringify(query)}`;
		const options = { headers: getHeaders() };

		return fetchJson(url, options).then(({ json }) => ({
			data: json.data,
			total: parseInt(json.pagination.count, 10),
		}));
	},
	getOne: (resource, params) => {
		let url = `${API_URL}/${resource}` + (params.id ? `/${params.id}` : "");
		let options = { headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json.data,
		}));
	},
	getMany: (resource, params) => {
		const query = {
			ids: params.ids.map((id) => (id.id ? id.id : id)).join(","),
		};
		const url = `${API_URL}/${resource}?${stringify(query)}`;
		const options = { headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json.data,
			total: parseInt(json.pagination.count, 10),
		}));
	},
	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const filter = Object.keys(params.filter || {}).reduce(
			(f, filterName) => ({
				...f,
				[filterName]:
					params.filter[filterName] instanceof Array
						? params.filter[filterName].join(",")
						: params.filter[filterName],
			}),
			{}
		);

		const query = {
			sort: field,
			direction: order,
			page: page,
			limit: perPage,
			[params.target]: params.id,
			...filter,
		};
		const url = `${API_URL}/${resource}?${stringify(query)}`;
		const options = { headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json.data,
			total: parseInt(json.pagination.count, 10),
		}));
	},
	create: (resource, params) =>
		prepareUpload(params.data).then((data) => {
			const url = `${API_URL}/${resource}`;
			const options = {
				method: "POST",
				body: JSON.stringify(prepareData(data)),
				headers: getHeaders(),
			};
			return fetchJson(url, options).then(({ json }) => ({
				data: { ...params.data, id: json.data.id },
			}));
		}),
	update: (resource, params) =>
		prepareUpload(params.data).then((data) => {
			const id = data && data.pk ? data.pk : params.id;
			const url = `${API_URL}/${resource}` + (id ? `/${id}` : "");
			const options = {
				method: "PUT",
				body: JSON.stringify(prepareData(data)),
				headers: getHeaders(),
			};
			return fetchJson(url, options).then(({ json }) => ({
				data: { id: data.pk, ...json.data },
			}));
		}),
	updateMany: (resource, params) => {
		return Promise.all(
			params.ids.map((id) =>
				fetchJson(`${API_URL}/${resource}/${id}`, {
					method: "PUT",
					body: JSON.stringify(params.data),
					headers: getHeaders(),
				})
			)
		).then((responses) => ({
			data: responses.map((response) => response.json),
		}));
	},
	delete: (resource, params) => {
		const url = `${API_URL}/${resource}/${params.id}`;
		const options = { method: "DELETE", headers: getHeaders() };
		return fetchJson(url, options).then(({ json }) => ({
			data: json,
		}));
	},
	deleteMany: (resource, params) => {
		return Promise.all(
			params.ids.map((id) =>
				fetch(`${API_URL}/${resource}/${id}`, {
					method: "DELETE",
					headers: getHeaders(),
				}).then((response) => response.json())
			)
		).then((responses) => {
			let errors = responses.filter(
				(r) =>
					r.data && r.data.code && (r.data.code === 409 || r.data.code === 403)
			);
			if (errors.length > 0) {
				return Promise.reject(errors.map((e) => e.data.message).join("\n"));
			}

			return {
				data: responses.map(({ json }) => ({ data: json })),
			};
		});
	},

	post(resource, params) {
		const url = `${API_URL}/${resource}`;
		const { body } = params;
		const options = {
			body: JSON.stringify(body),
			method: "POST",
			headers: getHeaders(),
		};
		return fetchJson(url, options).then(({ json }) => ({ data: json }));
	},
	get(resource, params) {
		const { query } = params;
		const queryString = stringify(query);
		const url = `${API_URL}/${resource}?${queryString}`;
		const options = {
			method: "GET",
			headers: getHeaders(),
		};
		return fetchJson(url, options).then(({ json }) => ({ data: json }));
	},
};

export default dataProvider;
