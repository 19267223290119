import { Button, ButtonGroup } from "@material-ui/core";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { get } from "lodash";
import React from "react";
import { useMutation, useNotify, useRefresh, useTranslate } from "react-admin";

const SetStatusButton = ({ record, status, Icon, ...props }) => {
	const translate = useTranslate();
	const refresh = useRefresh();
	const notify = useNotify();

	const [handleApprove, { loading }] = useMutation(
		{
			type: "update",
			resource: "user-profile-approvals",
			payload: {
				id: record.id,
				data: {
					user_id: record.user_id,
					status,
				},
			},
		},
		{
			onSuccess: ({ data }) => {
				notify(
					translate(`resources.user-profile-approvals.messages.${status}`)
				);
				refresh();
			},
			onError: () =>
				notify(translate("resources.user-profile-approvals.messages.error")),
		}
	);
	if (get(record, "status") === status) {
		return null;
	}
	return (
		<Button {...props} onClick={handleApprove} disabled={loading}>
			<Icon />
		</Button>
	);
};

const SettingsField = ({ record, onViewDetailsClick }) => {
	const handleClick = () => onViewDetailsClick(record);
	const isApproved = get(record, "status") === "approved";
	if (isApproved) {
		return null;
	}
	return (
		<ButtonGroup disableElevation color="primary" variant="text" size="small">
			<SetStatusButton
				record={record}
				status="approved"
				Icon={ThumbUpAltIcon}
			/>
			<Button onClick={handleClick}>
				<VisibilityIcon />
			</Button>
			<SetStatusButton
				record={record}
				status="rejected"
				Icon={ThumbDownAltIcon}
			/>
		</ButtonGroup>
	);
};

export default SettingsField;
